import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType.isRequired,
    sentry: PropTypes.any,
  };

  static defaultProps = {
    sentry: true,
  };

  state = {
    error: null,
  };

  componentDidCatch(error, info) {
    this.props.sentry.withScope(scope => {
      scope.setExtras(info);
      this.props.sentry.captureException(error);
      this.setState({ error });
    });
  }

  render() {
    const { component: Component, children, ...props } = this.props;

    if (this.state.error) {
      return <Component errorMessage={this.state.error.message} {...props} />;
    }

    return children;
  }
}

export default ErrorBoundary;
