import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./SelectFormField.module.scss";

const getValues = (input, values) => {
  if (
    input.value &&
    values.find(option => `${option.value}` === `${input.value}`)
  ) {
    return input.value;
  }
  return "";
};

const SelectFormField = ({
  label,
  classNameContainer,
  classNameLabel,
  classNameField,
  values,
  required,
  placeholder,
  placeholderDisabled,
  input,
  meta,
  displayEmpty,
}) => {
  return (
    <div
      className={classNames(
        styles.fieldContainer,
        classNameContainer && classNameContainer
      )}
    >
      <div
        className={classNames(
          styles.fieldLabel,
          classNameLabel && classNameLabel
        )}
      >
        {required && <span className={styles.required}>*</span>}
        {label}
      </div>
      <div
        className={classNames(styles.field, classNameField && classNameField)}
      >
        <select
          {...input}
          className={classNames(meta.invalid && meta.dirty && styles.invalid)}
          value={getValues(input, values)}
        >
          {displayEmpty && <option selected disabled hidden />}
          {!!placeholder && (
            <option
              label={placeholder}
              value={""}
              disabled={placeholderDisabled}
              className={styles.placeholder}
            >
              {placeholder}
            </option>
          )}
          {values.map(item => (
            <option
              key={item.value + item.label}
              value={item.value}
              label={item.label}
            >
              {item.label}
            </option>
          ))}
        </select>
        {meta.invalid && meta.dirty && (
          <div className={styles.error}>{meta.error}</div>
        )}
      </div>
    </div>
  );
};

SelectFormField.defaultProps = {
  required: false,
  values: [],
  placeholderDisabled: false,
};

SelectFormField.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  required: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderDisabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default SelectFormField;
