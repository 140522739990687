import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { compose } from "recompose";

import styles from "./PrimaryMenu.module.scss";
import {
  ADDRESS_BOOK,
  COLLECTIONS,
  DELIVERIES,
  DEPOT_FINDER,
  MY_DPD,
  MY_DPD_LOCAL,
  SHIPPING,
  SHOP_RETURNS,
} from "../../../constants/strings";
import Link from "./Link";
import { WithTheme } from "../../../hocs/withTheme";
import { getDomainLink } from "../../../utils/domain";
import { PRIMARY_MENU } from "../../../constants/analytics";

const getPrimaryMenu = name => {
  const domain = getDomainLink();
  return [
    { href: `${domain}/umslogon/welcome.do`, name },
    {
      href: `${domain}/app/online.html#shipment/create`,
      name: SHIPPING,
    },
    { href: `${domain}/collection`, name: COLLECTIONS },
    { href: `${domain}/deliverysearch/#search`, name: DELIVERIES },
    {
      href: `${domain}/app/online.html#returns/create`,
      name: SHOP_RETURNS,
    },
    { href: `${domain}/app/addressbook.html`, name: ADDRESS_BOOK },
    {
      href: `${domain}/apps/depotfinder/index.jsp`,
      name: DEPOT_FINDER,
    },
  ];
};

const StyledLink = styled(Link)`
  :hover {
    background-color: ${props => props.theme.palette.light};
  }
  &.active {
    background-color: ${props => props.theme.palette.light};
  }
`;

const PrimaryMenu = ({ theme, onMenuClick }) => {
  const { homeMenuItemName } = theme;
  const menu = getPrimaryMenu(homeMenuItemName);
  return (
    <div className={styles.primary_header}>
      <div className={classNames(styles.primary_navigation)}>
        {menu.map(menuItem => (
          <StyledLink
            {...menuItem}
            style={{
              active: styles.active,
              link: classNames(styles.link),
            }}
            key={menuItem.href}
            isActive={menuItem.name === homeMenuItemName}
            theme={theme}
            onClick={() => onMenuClick(menuItem.name)}
          />
        ))}
      </div>
    </div>
  );
};

PrimaryMenu.propTypes = {
  theme: PropTypes.object,
  onMenuClick: PropTypes.func,
};

export default compose(
  WithTheme,
  withTrackProps({
    onMenuClick: name => {
      switch (name) {
        case MY_DPD:
        case MY_DPD_LOCAL:
          return PRIMARY_MENU.CLICK_MYDPD;
        case SHIPPING:
          return PRIMARY_MENU.CLICK_SHIPPING;
        case COLLECTIONS:
          return PRIMARY_MENU.CLICK_COLLECTIONS;
        case DELIVERIES:
          return PRIMARY_MENU.CLICK_DELIVERIES;
        case SHOP_RETURNS:
          return PRIMARY_MENU.CLICK_SHOP_RETURNS;
        case ADDRESS_BOOK:
          return PRIMARY_MENU.CLICK_ADDRESS_BOOK;
        case DEPOT_FINDER:
          return PRIMARY_MENU.CLICK_DEPOT_FINDER;
      }
    },
  })
)(PrimaryMenu);
