import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./CustomLabel.module.scss";

export const CustomLabel = ({ label, labelStyles }) => {
  return <span className={classNames(styles.label, labelStyles)}>{label}</span>;
};

CustomLabel.propTypes = {
  label: PropTypes.string,
  labelStyles: PropTypes.string,
};
