import filter from "lodash/filter";

export const prepareSelectCurrencyData = currencies => {
  const webEntryCurrencies = filter(
    currencies,
    currency => currency.webEntry
  ).map(currency => ({
    label: `${currency.currencyDescription} (${currency.currencyCode})`,
    value: currency.currencyCode,
  }));
  return webEntryCurrencies.sort((a, b) => {
    if (a.value === "GBP") return -1;
    if (b.value === "GBP") return 1;
    if (a.value === "EUR") return -1;
    if (b.value === "EUR") return 1;

    return a.label > b.label ? 1 : -1;
  });
};
