import { createSelector } from "reselect";
import get from "lodash/get";

export const getCurrentUser = state => state.user.currentUser;

export const getUsername = createSelector(getCurrentUser, user =>
  get(user, "username", "")
);

export const getAccountId = createSelector(getCurrentUser, user =>
  get(user, "account", "")
);

export const getUserShippingVersion = createSelector(getCurrentUser, user =>
  get(user, "shippingVersion", 0)
);

export const getMessage = state => state.user.message;

export const getBusinessId = createSelector(getCurrentUser, user =>
  get(user, "businessId", "")
);

export const getForwardDateOver7Days = createSelector(getCurrentUser, user =>
  get(user, "advancedCustomerConfiguration.forwardDateOver7Days", false)
);

export const getUserErrorMessage = createSelector(
  getMessage,
  message => message
);
