import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

import { ENVIRONMENTS } from "../constants/common";

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_SENTRY_ENV,
} = process.env;

Sentry.init({
  debug: process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT,
  enabled: process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION,
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
});

export const sentryMiddleware = createSentryMiddleware(Sentry, {
  filterBreadcrumbActions: action => !action.type.startsWith("@@redux-form"),
});

export const setSentryUser = user => Sentry.setUser(user);

export default Sentry;
