import React from "react";
import PropTypes from "prop-types";

import styles from "./WarningText.module.scss";

const WarningText = ({ message }) => {
  return <span className={styles.text}>{message}</span>;
};

export default WarningText;

WarningText.propTypes = {
  message: PropTypes.string.isRequired,
};
