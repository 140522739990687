import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { rootReducer } from "../features/app/app.reducers";
import { ENVIRONMENTS } from "../constants/common";
import reduxCatchMiddleware from "./middlewares/reduxCatchMiddleware";
import errorHandler from "../handlers/errorHandler";
import { sentryMiddleware } from "../utils/sentry";

const middlewares = [
  thunk,
  reduxCatchMiddleware(errorHandler),
  sentryMiddleware,
];

if (process.env.NODE_ENV !== ENVIRONMENTS.PRODUCTION) {
  middlewares.push(require(`redux-logger`).logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
