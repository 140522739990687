import get from "lodash/get";

import { FIELDS, TITLE_FIELD, TITLE_VALUES } from "./contactDetails.constants";

export const mapDataToForm = ({ user }) => {
  return FIELDS.reduce((acc, field) => {
    const defaultValue = field === TITLE_FIELD ? TITLE_VALUES[0].value : "";
    acc[field] = get(user, field, defaultValue);
    return acc;
  }, {});
};
