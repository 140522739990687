import { ACTIONS } from "./config.actions";

export const initialState = {
  dpdServices: [],
  dpdLocalServices: [],
  serviceRestrictions: {},
  serviceRestrictionHelper: {},
  currencies: [],
  products: [],
  services: [],
  networks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_CURRENCIES.SUCCESS:
      return {
        ...state,
        currencies: action.payload.data,
      };
    case ACTIONS.FETCH_DPD_SERVICES.SUCCESS:
      return {
        ...state,
        dpdServices: action.payload.data,
        dpdLocalServices: action.payload.data,
      };
    case ACTIONS.FETCH_PRODUCTS.SUCCESS: {
      return {
        ...state,
        products: action.payload.data,
      };
    }
    case ACTIONS.FETCH_SERVICES.SUCCESS: {
      return {
        ...state,
        services: action.payload.data,
      };
    }
    case ACTIONS.FETCH_NETWORKS.SUCCESS: {
      return {
        ...state,
        networks: action.payload.data,
      };
    }
    // case ACTIONS.FETCH_CONFIG.SUCCESS:
    //   return {
    //     ...state,
    //     ...action.payload.data,
    //   };
    default:
      return state;
  }
};
