import { get } from "lodash";
import { createSelector } from "reselect";

import { prepareSelectCurrencyData } from "./config.models";

export const getConfig = state => state.config;

export const getCurrenciesOptions = createSelector(getConfig, config =>
  prepareSelectCurrencyData(config.currencies)
);

export const getProductOrServiceCode = date =>
  Number(get(date, "business_service", "").split("*")[1]);

export const getProducts = createSelector(
  getConfig,
  ({ products = [] }) => products
);

export const getProductOptions = createSelector(getProducts, products =>
  products.map(product => ({
    label: product.productDescription,
    value: product.productCode,
  }))
);

export const getServices = createSelector(
  getConfig,
  ({ services = [] }) => services
);

export const getServiceOptions = createSelector(getServices, services =>
  services.map(service => ({
    label: service.serviceDescription,
    value: service.serviceCode,
  }))
);

export const getNetworks = createSelector(
  getConfig,
  ({ networks = [] }) => networks
);

export const getNetworkOptions = createSelector(getNetworks, networks =>
  networks.map(network => ({
    label: network.networkDescription,
    value: network.networkCode,
  }))
);
