import React, { useContext } from "react";

import { ThemeContext } from "../utils/themeContext";

export const WithTheme = Component => {
  const ThemeComponent = props => {
    const { theme } = useContext(ThemeContext);
    return <Component {...props} theme={theme} />;
  };
  return ThemeComponent;
};
