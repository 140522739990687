import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./CheckboxInput.module.scss";

const CheckBoxInput = ({
  label,
  input,
  classNameContainer,
  classNameLabel,
  ...inputProps
}) => {
  return (
    <div
      className={classNames(
        styles.checkboxContainer,
        classNameContainer && classNameContainer
      )}
    >
      <input
        id={label}
        {...inputProps}
        {...input}
        type="checkbox"
        checked={input.value}
        className={styles.checkbox}
      />
      <label
        htmlFor={label}
        className={classNames(
          styles.checkboxLabel,
          classNameLabel && classNameLabel
        )}
      >
        {label}
      </label>
    </div>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default CheckBoxInput;
