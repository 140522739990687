import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  AUTO_PRINT,
  CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES,
  SCANNING_DEFAULTS,
} from "../../../constants/strings";
import { PRINT_ON_SCAN_FIELD } from "../../../features/shippingSettings/shippingSettings.constants";
import SubTitle from "../../../components/Title/SubTitle";
import RadioInput from "../../../components/RadioInput/RadioInput";
import styles from "./ScanningShippingSettings.module.scss";
import { SHIPPING_SETTINGS_IDS } from "../../../constants/analytics";

const ScanningShippingSettings = ({ onInputChange }) => {
  return (
    <>
      <SubTitle title={SCANNING_DEFAULTS} />
      <section className={styles.scanningContainer}>
        <div className={styles.labelWrapper}>
          <Field
            name={PRINT_ON_SCAN_FIELD}
            label={AUTO_PRINT}
            value="true"
            type="radio"
            component={RadioInput}
            classNameLabel={styles.container}
            onChange={() => {
              onInputChange(AUTO_PRINT);
            }}
          />
        </div>
        <div className={styles.labelWrapper}>
          <Field
            name={PRINT_ON_SCAN_FIELD}
            label={CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES}
            component={RadioInput}
            type="radio"
            value="false"
            onChange={() => {
              onInputChange(CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES);
            }}
          />
        </div>
      </section>
    </>
  );
};

ScanningShippingSettings.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default compose(
  withTrackProps(() => ({
    onInputChange: type => {
      switch (type) {
        case AUTO_PRINT:
          return SHIPPING_SETTINGS_IDS.CLICK_RADIO_BUTTON_AUTO_PRINT;
        case CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES:
          return SHIPPING_SETTINGS_IDS.CLICK_RADIO_BUTTON_TOTAL_NO_OF_PACKAGES;
      }
    },
  }))
)(ScanningShippingSettings);
