import { formatMessage } from "../../utils/string";
import { EMAIL_FIELD, FIELDS, PHONE_FIELD } from "./contactDetails.constants";
import {
  EMAIL_REGEX,
  FIELD_CANT_BE_EMPTY,
  INVALID_EMAIL,
  INVALID_TELEPHONE_NUMBER,
  PHONE_REGEX,
} from "../../constants/strings";

export const validate = (props, formProps) => {
  const errors = {};
  if (!formProps.initialized) {
    return errors;
  }

  if (
    props[EMAIL_FIELD] &&
    props[EMAIL_FIELD].trim() &&
    !EMAIL_REGEX.test(props[EMAIL_FIELD])
  ) {
    errors[EMAIL_FIELD] = INVALID_EMAIL;
  }

  if (
    props[PHONE_FIELD] &&
    props[PHONE_FIELD].trim() &&
    !PHONE_REGEX.test(props[PHONE_FIELD])
  ) {
    errors[PHONE_FIELD] = INVALID_TELEPHONE_NUMBER;
  }

  const invalidFields = FIELDS.reduce((acc, key) => {
    if (!props[key] || !props[key].trim()) {
      acc[key] = formatMessage(FIELD_CANT_BE_EMPTY, "Field");
    }

    return acc;
  }, {});

  return { ...errors, ...invalidFields };
};
