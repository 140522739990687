import React from "react";
import PropTypes from "prop-types";

import styles from "./SubTitle.module.scss";

const SubTitle = ({ title }) => {
  return (
    <div className={styles.subTitleContainer}>
      <div className={styles.subTitle}>{title}</div>
    </div>
  );
};

SubTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SubTitle;
