import { ACTIONS } from "./printingSettings.actions";
import { ACTIONS as UserActions } from "../user/user.actions";

export const initialState = {
  settings: {},
  isMyDPDRunning: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserActions.FETCH_USER.SUCCESS:
      return {
        ...state,
        settings: action.payload.prefsConfigSettings,
      };

    case ACTIONS.SAVE_NEW_PRINTING_SETTINGS.SUCCESS:
      return {
        ...state,
        settings: action.payload,
      };

    case ACTIONS.CHECK_MYDPD_APP_RUNNING.FAILURE:
      return {
        ...state,
        isMyDPDRunning: false,
      };
    case ACTIONS.CHECK_MYDPD_APP_RUNNING.SUCCESS:
      return {
        ...state,
        isMyDPDRunning: true,
      };
    default:
      return state;
  }
};
