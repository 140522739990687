import get from "lodash/get";

import {
  BOOL_FIELDS,
  CHECKBOX_FIELD,
  DATE_FIELDS,
  DIMENSION_FIELD,
  DIMENSION_FIELDS,
  DOMESTIC_FIELD,
  DOMESTIC_FIELDS,
  DROPDOWN_FIELDS,
  GENERAL_FIELDS,
  INTERNATIONAL_FIELD,
  INTERNATIONAL_FIELDS,
  NUMBER_FIELDS,
  RADIO_FIELDS,
  SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD,
  SHIPPING_DEFAULTS,
} from "./shippingSettings.constants";
import { addDays } from "../../utils/dateUtils";
import { getAnyway } from "../../utils/objectUtils";

const isFieldForModel = (field, fields) => fields.includes(field);

const convertIfNeed = (field, formValues) => {
  if (NUMBER_FIELDS.includes(field)) {
    return Number(formValues[field]) || null;
  } else if (RADIO_FIELDS.includes(field)) {
    return formValues[field] === "true";
  } else if (DATE_FIELDS.includes(field)) {
    return formValues[field] ? new Date(formValues[field]) : null;
  } else if (DIMENSION_FIELDS.includes(field)) {
    return getAnyway(formValues, field);
  } else if (field === SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD) {
    return Number(getAnyway(formValues, field, 1));
  } else if (DROPDOWN_FIELDS.includes(field)) {
    return formValues[field] || null;
  } else if (CHECKBOX_FIELD.includes(field)) {
    return getAnyway(formValues, field, false);
  }
  return getAnyway(formValues, field);
};

const prepareDataToForm = (user, fieldNames, path) => {
  return fieldNames.reduce((acc, field) => {
    if (DATE_FIELDS.includes(field)) {
      const date = get(user, `${path}${field}`, null);
      const isWeekendEnabled = get(user, `${path}enableWeekend`, false);
      acc[field] = date
        ? transformDate(date, isWeekendEnabled)
        : transformDate(date, isWeekendEnabled);
    } else if (BOOL_FIELDS.includes(field)) {
      acc[field] = Boolean(get(user, `${path}${field}`, false));
    } else if (RADIO_FIELDS.includes(field)) {
      acc[field] = String(get(user, `${path}${field}`, "true"));
    } else if (field === SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD) {
      acc[field] = get(user, `${path}${field}`, 1);
    } else {
      acc[field] = get(user, `${path}${field}`, "");
    }
    return acc;
  }, {});
};

export const transformDate = (date, isWeekendEnabled) => {
  let newDate = !date || date < new Date() ? new Date() : date;

  if (!isWeekendEnabled) {
    newDate = getWeekendNextMonday(newDate);
  }
  return new Date(newDate);
};

export const getWeekendNextMonday = date => {
  if (isWeekend(date)) {
    return date.getDay() === 6 ? addDays(date, 2) : addDays(date, 1);
  }
  return date;
};

export const getGeneralData = user =>
  prepareDataToForm(user, GENERAL_FIELDS, `${SHIPPING_DEFAULTS}.`);

export const getDomesticData = user =>
  prepareDataToForm(
    user,
    DOMESTIC_FIELDS,
    `${SHIPPING_DEFAULTS}.${DOMESTIC_FIELD}.`
  );

export const getInternationalData = user =>
  prepareDataToForm(
    user,
    INTERNATIONAL_FIELDS,
    `${SHIPPING_DEFAULTS}.${INTERNATIONAL_FIELD}.`
  );

export const getDimensionData = user =>
  prepareDataToForm(
    user,
    DIMENSION_FIELDS,
    `${SHIPPING_DEFAULTS}.${INTERNATIONAL_FIELD}.${DIMENSION_FIELD}.`
  );

export const mapDataToForm = ({ user }) => ({
  ...getGeneralData(user),
  ...getDomesticData(user),
  ...getDimensionData(user),
  ...getInternationalData(user),
});

const prepareDataFromForm = (formValues, fieldNames) => {
  return Object.keys(formValues).reduce((acc, field) => {
    if (isFieldForModel(field, fieldNames)) {
      acc[field] = convertIfNeed(field, formValues);
    }
    return acc;
  }, {});
};

export const getInternationalFields = formValues => ({
  ...prepareDataFromForm(formValues, INTERNATIONAL_FIELDS),
  [DIMENSION_FIELD]: prepareDataFromForm(formValues, DIMENSION_FIELDS),
});

export const prepareData = formValues => {
  return {
    ...prepareDataFromForm(formValues, GENERAL_FIELDS),
    [DOMESTIC_FIELD]: prepareDataFromForm(formValues, DOMESTIC_FIELDS),
    [INTERNATIONAL_FIELD]: getInternationalFields(formValues),
  };
};

export const isWeekend = shippingDate => {
  const dayNumber = new Date(shippingDate).getDay();
  return dayNumber === 0 || dayNumber === 6;
};
