import React from "react";
import { useSelector } from "react-redux";

import styles from "./AccountInfo.module.scss";
import { ACCOUNT_NUMBER, USER_LOGIN_ID } from "../../../constants/strings";
import {
  getAccountId,
  getUsername,
} from "../../../features/user/user.selectors";

export default function AccountInfo() {
  const username = useSelector(getUsername);
  const accountId = useSelector(getAccountId);
  return (
    <div className={styles.account_container}>
      <div className={styles.flex}>{`${ACCOUNT_NUMBER}: ${accountId}`}</div>
      <div className={styles.flex}>{`${USER_LOGIN_ID}: ${username}`}</div>
    </div>
  );
}
