export const ENVIRONMENTS = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
};
export const WEB_BASED_SYSTEM = "WBS";

export const API_ENDPOINTS = {
  AUTH: "/preferences/auth",
  CONFIG: "/preferences/config",
  REFERENCE: "/preferences/reference",
  USER: "/preferences/user",
};

export const dpdLocalDomain = "dpdlocal";

export const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];

export const CUSTOM_DOMAIN = {
  DPD_CO_UK: "dpd.co.uk",
  DPD_LOCAL_CO_UK: "dpdlocal.co.uk",
};
