import React from "react";
import { Field } from "redux-form";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import {
  ALLOW_PARCELS_TO_BE_SHIPPED,
  COUNTRY,
  DATE_FORMAT_DD_MM_YYYY,
  DEFAULT_INFORMATION,
  DEFAULT_SHIPMENT_DATE,
  DEFAULT_SHIPPING_ADDRESS_BOOK_SEARCH_TYPE,
  PLEASE_NOTE_THAT_WHET_SHIPPING_PARCELS,
  PLEASE_SELECT,
  SELECT_A_COUNTRY,
  USE_SHARED_ADDRESS_BOOK,
} from "../../../constants/strings";
import {
  ADDRESS_BOOK_OPTIONS,
  DEFAULT_INFORMATION_1_FIELD,
  DEFAULT_INFORMATION_2_FIELD,
  DEFAULT_INFORMATION_3_FIELD,
  DEFAULT_INFORMATION_4_FIELD,
  DEFAULT_INFORMATION_5_FIELD,
  DEFAULT_SHIPMENT_DATE_FIELD,
  ENABLE_WEEKEND_FIELD,
  INT_COUNTRY_FIELD,
  SHARE_PROFILE_FIELD,
  SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD,
} from "../../../features/shippingSettings/shippingSettings.constants";
import CheckBoxInput from "../../../components/CheckBoxInput/CheckBoxInput";
import InputWithTitle from "../../../components/InputWithTitle/InputWithTitle";
import SelectFormField from "../../../components/select/SelectFormField";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { formatMessage } from "../../../utils/string";
import { addDays, getWeekends } from "../../../utils/dateUtils";
import { getCountriesSortedList } from "../../../features/country/country.selectors";
import {
  getEnableWeekend,
  getShippingDate,
} from "../../../features/shippingSettings/shippingSettings.selectors";
import styles from "./GeneralShippingSettings.module.scss";
import { changeDateOnAllowWeekendUncheck } from "../../../features/shippingSettings/shippingSettings.actions";
import { SHIPPING_SETTINGS_IDS } from "../../../constants/analytics";
import { ReactComponent as Icon } from "../../../assets/icons/info.svg";
import { getForwardDateOver7Days } from "../../../features/user/user.selectors";

const GeneralShippingSettings = ({
  onInputChange,
  onChangeWeekend,
  onChangeWeekendAnalytics,
  forwardDateOver7Days,
}) => {
  const countries = useSelector(getCountriesSortedList);
  const enableWeekend = useSelector(getEnableWeekend);
  const shippingDate = useSelector(getShippingDate);

  return (
    <>
      <Field
        name={SHARE_PROFILE_FIELD}
        label={USE_SHARED_ADDRESS_BOOK}
        component={CheckBoxInput}
      />
      <Field
        component={SelectFormField}
        label={COUNTRY}
        name={INT_COUNTRY_FIELD}
        values={countries}
        placeholder={SELECT_A_COUNTRY}
        onChange={() => {
          onInputChange(INT_COUNTRY_FIELD);
        }}
      />

      <Field
        component={SelectFormField}
        label={DEFAULT_SHIPPING_ADDRESS_BOOK_SEARCH_TYPE}
        name={SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD}
        values={ADDRESS_BOOK_OPTIONS}
        placeholder={PLEASE_SELECT}
        onChange={() => {
          onInputChange(SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD);
        }}
      />
      <Field
        name={DEFAULT_SHIPMENT_DATE_FIELD}
        component={CustomDatePicker}
        label={DEFAULT_SHIPMENT_DATE}
        placeholderText={DATE_FORMAT_DD_MM_YYYY.toUpperCase()}
        dateFormat={DATE_FORMAT_DD_MM_YYYY}
        showDisabledMonthNavigation
        excludeDates={
          enableWeekend
            ? []
            : getWeekends(
                new Date(),
                forwardDateOver7Days
                  ? addDays(new Date(), 30)
                  : addDays(new Date(), 7)
              )
        }
        onChange={value => {
          onInputChange(DEFAULT_SHIPMENT_DATE_FIELD, value);
        }}
        minDate={new Date()}
        maxDate={
          forwardDateOver7Days
            ? addDays(new Date(), 30)
            : addDays(new Date(), 7)
        }
      />
      <Field
        name={ENABLE_WEEKEND_FIELD}
        component={CheckBoxInput}
        label={ALLOW_PARCELS_TO_BE_SHIPPED}
        onChange={event => {
          onChangeWeekendAnalytics();
          !event.target.checked && onChangeWeekend(new Date(shippingDate));
        }}
      />
      {!!enableWeekend && (
        <div className={styles.infoContainer}>
          <div className={styles.icon}>
            <Icon />
          </div>
          <span>{PLEASE_NOTE_THAT_WHET_SHIPPING_PARCELS}</span>
        </div>
      )}
      <Field
        name={DEFAULT_INFORMATION_1_FIELD}
        component={InputWithTitle}
        label={formatMessage(DEFAULT_INFORMATION, "1")}
        maxLength={50}
      />
      <Field
        name={DEFAULT_INFORMATION_2_FIELD}
        component={InputWithTitle}
        label={formatMessage(DEFAULT_INFORMATION, "2")}
        maxLength={50}
      />
      <Field
        name={DEFAULT_INFORMATION_3_FIELD}
        component={InputWithTitle}
        label={formatMessage(DEFAULT_INFORMATION, "3")}
        maxLength={50}
      />
      <Field
        name={DEFAULT_INFORMATION_4_FIELD}
        component={InputWithTitle}
        label={formatMessage(DEFAULT_INFORMATION, "4")}
        maxLength={50}
      />
      <Field
        name={DEFAULT_INFORMATION_5_FIELD}
        component={InputWithTitle}
        label={formatMessage(DEFAULT_INFORMATION, "5")}
        maxLength={50}
      />
    </>
  );
};

GeneralShippingSettings.propTypes = {
  onInputChange: PropTypes.func,
  onChangeWeekend: PropTypes.func,
  forwardDateOver7Days: PropTypes.bool,
  onChangeWeekendAnalytics: PropTypes.func,
};

const mapStateToProps = state => ({
  forwardDateOver7Days: getForwardDateOver7Days(state),
});

export default compose(
  connect(mapStateToProps, {
    onChangeWeekend: shippingDate => dispatch =>
      dispatch(changeDateOnAllowWeekendUncheck(shippingDate)),
  }),
  withTrackProps(props => ({
    onInputChange: (type, value) => {
      switch (type) {
        case INT_COUNTRY_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_COUNTRY;
        case SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_ADDRESSBOOK_TYPE;
        case DEFAULT_SHIPMENT_DATE_FIELD:
          return createLogEntryPayload(
            SHIPPING_SETTINGS_IDS.CLICK_DEFAULT_SHIPMENT_DATE,
            { value }
          );
      }
    },
    onChangeWeekendAnalytics: () =>
      SHIPPING_SETTINGS_IDS.CLICK_CHECKBOX_ALLOW_PARCELS_TO_BE_SHIPPED,
  }))
)(GeneralShippingSettings);
