import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";
import { Helmet } from "react-helmet";

import { launchApp } from "./features/app/app.actions";
import { Routes } from "./router";
import tracker from "./utils/eventTracker";
import Sentry from "./utils/sentry";
import ErrorBoundary from "./components/ErrorBoundary";
import SomethingWentWrong from "./components/SomethingWentWrong";
import ThemeProvider from "./utils/themeProvider";
import { isDpdLocalDomain } from "./utils/domain";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props.launchApp();
  }

  renderMeta = () => (
    <Helmet>
      <title>
        {isDpdLocalDomain()
          ? "DPD Local - My Account Details"
          : "DPD (UK) - My Account Details"}
      </title>
    </Helmet>
  );

  render() {
    return (
      <ErrorBoundary component={SomethingWentWrong} sentry={Sentry}>
        <TrackerProvider tracker={tracker}>
          <ThemeProvider>
            {this.renderMeta()}
            <Routes />
          </ThemeProvider>
        </TrackerProvider>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  launchApp: PropTypes.func,
};

const mapDispatchToProps = {
  launchApp,
};

export default connect(null, mapDispatchToProps)(App);
