import { isDpdLocalDomain } from "./domain";

export const changeFavicon = () => {
  let src = `dpdFavicon.ico?time=${new Date().getTime()}`;
  if (isDpdLocalDomain()) {
    src = `localFavicon.svg?time=${new Date().getTime()}`;
  }
  const link = document.createElement("link");
  const oldLink = document.getElementById("dynamic-favicon");
  link.id = "dynamic-favicon";
  link.rel = "shortcut icon";
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};
