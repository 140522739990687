import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as userService from "./user.service";
import { getCurrentUser as getUser } from "./user.selectors";

const ACTION_NAMESPACE = "USER";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_USER: createAsyncActionTypes("FETCH_USER"),
  UPDATE_USER: createAsyncActionTypes("UPDATE_USER"),
  UPDATE_SETTINGS: createAsyncActionTypes("UPDATE_SETTINGS"),
});

export const getCurrentUser = createAsyncAction(
  () => userService.getCurrentUser(),
  ACTIONS.FETCH_USER
);

export const updateSettings = createAsyncAction(
  values => async (dispatch, getState) => {
    const { uid } = getUser(getState());
    return dispatch(updateUserData(uid, { ...values }));
  },
  ACTIONS.UPDATE_SETTINGS
);

export const updateUserData = createAsyncAction(
  (uid, userModel) => () => {
    return userService.updateUser(uid, userModel);
  },
  ACTIONS.UPDATE_USER
);
