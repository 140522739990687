import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

import styles from "./Title.module.scss";
import { WithTheme } from "../../hocs/withTheme";

const StyledContainer = styled.div`
  background-color: ${props => props.theme.palette.main};
`;

const Title = ({ title, theme }) => {
  return (
    <StyledContainer className={classNames(styles.container)} theme={theme}>
      <div className={styles.title}>{title}</div>
    </StyledContainer>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

export default WithTheme(Title);
