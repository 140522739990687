import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import styles from "./Layout.module.scss";
import { COPYRIGHT } from "../../constants/strings";
import Header from "./components/Header";
import AccountInfo from "./components/AccountInfo";
import { HOME_PAGE } from "../../constants/analytics";

function Layout({ children, MenuComponent }) {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.main_content}>
        <MenuComponent />
        <div className={styles.main}>
          <AccountInfo />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      <p className={styles.copyright}>{COPYRIGHT}</p>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  MenuComponent: PropTypes.elementType,
};

export default compose(
  withTrack({
    loadId: HOME_PAGE.LOAD,
    interfaceId: HOME_PAGE.INTERFACE_ID,
  })
)(Layout);
