import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { loginUser } from "../auth/auth.actions";
import { printerService } from "./app.service";
import {
  getSelectedPrinterDevice,
  getThermalPrinterType,
} from "../printingSettings/printingSettings.selectors";
import { fetchCountries } from "../country/country.actions";
import {
  fetchConfig,
  fetchCurrencies,
  fetchDpdServices,
  fetchNetworks,
  fetchProducts,
  fetchServices,
} from "../config/config.actions";
import { getCurrentUser } from "../user/user.actions";
import * as sentryUtils from "../../utils/sentry";
import { setUserProperties } from "../../utils/eventTracker";

const { REACT_APP_LOGIN_EMAIL, REACT_APP_LOGIN_PASSWORD } = process.env;
const ACTION_NAMESPACE = "APP";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CONFIGURE_PRINTER: createAsyncActionTypes("CONFIGURE_PRINTER"),
});

export const fetchStaticData = businessId => dispatch =>
  Promise.all([
    dispatch(fetchCountries()),
    dispatch(fetchConfig()),
    dispatch(fetchDpdServices()),
    dispatch(fetchCurrencies()),
    dispatch(fetchProducts({ businessId })),
    dispatch(fetchServices({ businessId })),
    dispatch(fetchNetworks({ businessId })),
  ]);

export const launchApp = () => async dispatch => {
  if (REACT_APP_LOGIN_EMAIL || REACT_APP_LOGIN_PASSWORD) {
    await dispatch(loginUser(REACT_APP_LOGIN_EMAIL, REACT_APP_LOGIN_PASSWORD));
  }
  const user = await dispatch(getCurrentUser());
  const { email, username, uid, businessId } = user;
  setUserProperties(user);
  sentryUtils.setSentryUser({
    email,
    displayName: username,
    uid,
  });

  await dispatch(fetchStaticData(businessId));
};

export const configurePrinter = createAsyncAction(
  () => async () => printerService.configureUsbPrinter(),
  ACTIONS.CONFIGURE_PRINTER
);

export const testPrinter = () => (dispatch, getState) => {
  const state = getState();
  const device = getSelectedPrinterDevice(state);
  const printerType = getThermalPrinterType(state);

  return printerService.printLabelTest(device, printerType);
};
