export const TITLE_FIELD = "title";
export const FORENAME_FIELD = "forename";
export const LASTNAME_FIELD = "lastname";
export const POSITION_FIELD = "position";
export const EMAIL_FIELD = "email";
export const PHONE_FIELD = "phone";

export const FIELDS = [
  TITLE_FIELD,
  FORENAME_FIELD,
  LASTNAME_FIELD,
  POSITION_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
];

export const TITLE_VALUES = [
  { label: "Mr", value: "mr" },
  { label: "Mrs", value: "mrs" },
  { label: "Miss", value: "miss" },
  { label: "Ms", value: "ms" },
];
