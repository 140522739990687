import {
  CLOSING_TIME_FIELD,
  NUMBER_OF_ITEMS_FIELD,
  WEIGHT_FIELD,
} from "./collectionDefaults.constants";
import {
  INVALID_TIME,
  MUST_BE_A_POSITIVE_NUMBER,
  MUST_BE_A_POSITIVE_WHOLE_NUMBER,
  TIME_REGEX,
} from "../../constants/strings";

export const validate = props => {
  const errors = {};

  if (props[NUMBER_OF_ITEMS_FIELD]) {
    const value = parseFloat(props[NUMBER_OF_ITEMS_FIELD]);
    if (isNaN(props[NUMBER_OF_ITEMS_FIELD]) || !Number.isInteger(value)) {
      errors[NUMBER_OF_ITEMS_FIELD] = MUST_BE_A_POSITIVE_WHOLE_NUMBER;
    }
  }

  if (props[WEIGHT_FIELD]) {
    const value = parseFloat(props[WEIGHT_FIELD]);
    if (isNaN(props[WEIGHT_FIELD]) || value < 0) {
      errors[WEIGHT_FIELD] = MUST_BE_A_POSITIVE_NUMBER;
    }
  }

  if (
    props[CLOSING_TIME_FIELD] &&
    props[CLOSING_TIME_FIELD].trim() &&
    !TIME_REGEX.test(props[CLOSING_TIME_FIELD])
  ) {
    errors[CLOSING_TIME_FIELD] = INVALID_TIME;
  }

  return { ...errors };
};
