import FetchClient from "@dpdgroupuk/fetch-client";

import { getLocalApisUrl } from "./domain";

const printerApi = new FetchClient({
  credentials: "include",
  baseUrl: getLocalApisUrl(window.location, "2917"),
});

export default printerApi;
