import { FORBIDDEN, UNAUTHORIZED } from "http-status-codes";

import { externalNavigateTo } from "../router/navigation";
import * as sentryUtils from "../utils/sentry";
import { getDomainLink } from "../utils/domain";

export default (error, getState, next, lastAction, dispatch) => {
  if (error.statusCode === UNAUTHORIZED || error.statusCode === FORBIDDEN) {
    sentryUtils.setSentryUser(null);
    return externalNavigateTo(`${getDomainLink()}/umslogon/public/logout.do`);
  }

  return next(lastAction);
};
