import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import get from "lodash/get";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { compose } from "recompose";

import styles from "./SecondaryMenu.module.scss";
import {
  API,
  DIAGNOSTICS,
  MY_ACCOUNT_DETAILS,
  REPORTS,
  WELCOME,
} from "../../../constants/strings";
import Link from "./Link";
import { WithTheme } from "../../../hocs/withTheme";
import { getDomainLink, isDpdLocalDomain } from "../../../utils/domain";
import { PRIMARY_MENU, SECONDARY_MENU } from "../../../constants/analytics";

const getSecondaryMenu = () => {
  const domain = getDomainLink();
  const isDpdLocal = isDpdLocalDomain();
  return {
    links: [
      { href: `${domain}/umslogon/welcome.do`, name: WELCOME },
      {
        href: "/",
        name: MY_ACCOUNT_DETAILS,
      },
      {
        href: `${domain}/reports/reportsList.do`,
        name: REPORTS,
        hidden: isDpdLocal,
      },
      { href: `${domain}/apps/diagnostics/`, name: DIAGNOSTICS },
      { href: `${domain}/apps/api/`, name: API },
    ],
    deliverySearch: `${domain}/deliverysearch/#results&quickTrack=`,
  };
};

const StyledSearch = styled.input`
  background-color: ${props => props.theme.palette.light};
  &:focus {
    background-color: ${props => props.theme.palette.light};
  }
`;

const SecondaryMenu = ({ theme, onMenuClick, onClickSearch }) => {
  const activeSecondary = MY_ACCOUNT_DETAILS;
  const { links, deliverySearch } = getSecondaryMenu();
  const [searchValue, setSearch] = useState("");

  return (
    <div className={styles.secondary_container}>
      <div className={styles.secondary_header}>
        <div className={styles.secondary_navigation}>
          {links.map(menuItem => {
            const hidden = get(menuItem, "hidden");
            return (
              !hidden && (
                <Link
                  key={menuItem.href}
                  href={menuItem.href}
                  name={menuItem.name}
                  style={{ active: styles.active }}
                  isActive={menuItem.name === activeSecondary}
                  onClick={() => onMenuClick(menuItem.name)}
                />
              )
            );
          })}
        </div>
      </div>
      <form action={`${deliverySearch}${searchValue}`}>
        <div className={classNames(styles.form_container)}>
          <input
            type="text"
            size="25"
            maxLength="100"
            placeholder="Delivery Search"
            className={styles.search}
            value={searchValue}
            onChange={({ target: { value } }) => setSearch(value)}
          />
          <StyledSearch
            type="submit"
            className={classNames(styles.submit)}
            value=""
            theme={theme}
            disabled={!searchValue}
            onClick={onClickSearch}
          />
        </div>
      </form>
    </div>
  );
};

SecondaryMenu.propTypes = {
  theme: PropTypes.object,
  onMenuClick: PropTypes.func,
  onClickSearch: PropTypes.func,
};

export default compose(
  WithTheme,
  withTrackProps(() => ({
    onClickSearch: () => PRIMARY_MENU.CLICK_SEARCH,
    onMenuClick: name => {
      switch (name) {
        case WELCOME:
          return SECONDARY_MENU.CLICK_WELCOME;
        case MY_ACCOUNT_DETAILS:
          return SECONDARY_MENU.CLICK_MY_ACCOUNT_DETAILS;
        case REPORTS:
          return SECONDARY_MENU.CLICK_REPORTS;
        case DIAGNOSTICS:
          return SECONDARY_MENU.CLICK_DIAGNOSTICS;
        case API:
          return SECONDARY_MENU.CLICK_API;
      }
    },
  }))
)(SecondaryMenu);
