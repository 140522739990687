import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as authService from "./auth.service";

const ACTION_NAMESPACE = "AUTH";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SIGN_IN_WITH_PASSWORD: createAsyncActionTypes("SIGN_IN_WITH_PASSWORD"),
  LOGIN_USER: createAsyncActionTypes("LOGIN_USER"),
  SIGN_OUT: createAsyncActionTypes("SIGN_OUT"),
});

export const loginUser = createAsyncAction(
  (email, password) => async dispatch => {
    const { user } = await dispatch(
      signInWithPassword({
        email,
        password,
      })
    );
    const idToken = await user.getIdToken();

    await dispatch(signOut());

    return authService.login(idToken);
  },
  ACTIONS.LOGIN_USER
);

export const signOut = createAsyncAction(authService.signOut, ACTIONS.SIGN_OUT);

export const signInWithPassword = createAsyncAction(
  ({ email, password }) => () =>
    authService.signInWithEmailAndPassword(email, password),
  ACTIONS.SIGN_IN_WITH_PASSWORD
);
