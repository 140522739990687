import React from "react";
import PropTypes from "prop-types";

import styles from "./InnerLayout.module.scss";

export default function InnerLayout({ children }) {
  return (
    <div className={styles.content}>
      <div className={styles.column}>{children}</div>
    </div>
  );
}

InnerLayout.propTypes = {
  children: PropTypes.node,
};
