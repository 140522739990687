import { API_ENDPOINTS } from "../../constants/common";
import api from "../../utils/api";
import printerApi from "../../utils/printerApi";

export const ping = () => printerApi.get("/ping");

export const saveNewPrinterSettings = (userId, params = {}) =>
  api
    .put({
      path: `/${API_ENDPOINTS.USER}/${userId}/printerSettings`,
      body: params,
    })
    .then(() => params);
