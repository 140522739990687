import React from "react";
import PropTypes from "prop-types";
import { Field, isValid, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Title from "../../components/Title/Title";
import InnerLayout from "../../components/Layout/InnerLayout";
import styles from "./PrintingSettings.module.scss";
import { PRINTING_SETTING_FORM } from "../../constants/forms";
import {
  ASSET_TRACKING,
  ASSET_TRACKING_DPD,
  CONFIGURE_USB_PRINTER,
  ERROR_SAVING_MSG,
  IF_UNABLE_READ_ASSET_NUMBER,
  LABELS_PER_PAGE,
  LABELS_PER_PAGE_RADIO_ONE,
  LABELS_PER_PAGE_RADIO_TWO,
  LASER_PRINTER_SETTINGS,
  PRINTING_SETTINGS_DETAILS,
  PRINTING_SETTINGS_MSG,
  PRINTING_TO_THERMAL_PRINTER,
  SELECT_PRINTER,
  TEST_PRINTER,
  THERMAL_PRINTER_ASSET_NUMBER,
  THERMAL_PRINTER_CAN_BE_FOUND,
  THERMAL_PRINTER_NAME,
  THERMAL_PRINTER_SETTINGS,
  THERMAL_PRINTER_TYPE,
} from "../../constants/strings";
import FormBottomSection from "../../components/formBottomSection/FormBottomSection";
import { getUserErrorMessage } from "../../features/user/user.selectors";
import SubTitle from "../../components/Title/SubTitle";
import SelectFormField from "../../components/select/SelectFormField";
import InputWithTitle from "../../components/InputWithTitle/InputWithTitle";
import { CustomLabel } from "../../components/Header/CustomLabel";
import HintLabel from "../../components/HintLabel/HintLabel";
import CheckBoxInput from "../../components/CheckBoxInput/CheckBoxInput";
import {
  LABELS_PER_PAGE_FIELD,
  MISSING_ASSET_NUMBER,
  MISSING_ASSET_NUMBER_FIELD,
  PRINT_TO_THERMAL_FIELD,
  THERMAL_PRINTER_ASSET_NUMBER_FIELD,
  THERMAL_PRINTER_NAME_FIELD,
  THERMAL_PRINTER_TYPE_FIELD,
  THERMAL_PRINTER_TYPES,
} from "../../features/printingSettings/printingSettings.constants";
import RadioInput from "../../components/RadioInput/RadioInput";
import { validate } from "../../features/printingSettings/printingSettings.validators.js";
import {
  getMissingAssetNumberFieldValue,
  getPrintToThermal,
  isPrinterSelected,
  shouldShowMyDPDCustomerAppMigration,
} from "../../features/printingSettings/printingSettings.selectors";
import {
  changePrintingSettingsFieldValue,
  initializeForm,
  savePrintingSettings,
} from "../../features/printingSettings/printingSettings.actions";
import AssetTracking from "../../assets/images/assetTracking.gif";
import AssetTrackingDpdgroup from "../../assets/images/assetTrackingDpdgroup.png";
import { isElectronEnvironment } from "../../utils/checkUserAgent";
import { printerService } from "../../features/app/app.service";
import { preparePrinters } from "../../features/app/app.models";
import { configurePrinter, testPrinter } from "../../features/app/app.actions";
import { PRINTING_SETTINGS_IDS } from "../../constants/analytics";
import MyDPDCustomerCard from "../MyDPDCustomer/MyDPDCustomer";

const isFromElectron = isElectronEnvironment();

const PrintingSettings = ({
  handleSubmit,
  onInputChange,
  onSave,
  onTestPrinter,
  message,
  onChangeAssetNumber,
  onConfigurePrinter,
  showMyDPDAppMigration,
}) => {
  if (message === ERROR_SAVING_MSG) {
    message = `${message} ${PRINTING_SETTINGS_MSG}`;
  }

  const isPrintingFormValid = useSelector(isValid(PRINTING_SETTING_FORM));
  const isMissingAssetNumberChecked = useSelector(
    getMissingAssetNumberFieldValue
  );
  const isPrintToThermalChecked = useSelector(getPrintToThermal);
  const isPrinterChosen = useSelector(isPrinterSelected);

  return (
    <div className={styles.container}>
      <Title title={PRINTING_SETTINGS_DETAILS} />

      <InnerLayout>
        <HintLabel />
        {isFromElectron && (
          <>
            <Field
              name={PRINT_TO_THERMAL_FIELD}
              component={CheckBoxInput}
              label={PRINTING_TO_THERMAL_PRINTER}
              classNameContainer={styles.printToTerminal}
              onChange={() => {
                onInputChange(PRINT_TO_THERMAL_FIELD);
              }}
            />
            {isPrintToThermalChecked && (
              <>
                <SubTitle title={THERMAL_PRINTER_SETTINGS} />
                <Field
                  displayEmpty
                  name={THERMAL_PRINTER_TYPE_FIELD}
                  component={SelectFormField}
                  label={THERMAL_PRINTER_TYPE}
                  values={THERMAL_PRINTER_TYPES}
                  maxLength={50}
                  onChange={() => {
                    onInputChange(THERMAL_PRINTER_TYPE_FIELD);
                  }}
                />
                <div className={styles.printerNameWrapper}>
                  <Field
                    name={THERMAL_PRINTER_NAME_FIELD}
                    component={SelectFormField}
                    label={THERMAL_PRINTER_NAME}
                    required
                    values={preparePrinters(printerService.listPrinters())}
                    placeholder={SELECT_PRINTER}
                    placeholderDisabled
                    maxLength={50}
                    classNameContainer={styles.printToTerminal}
                    onChange={() => {
                      onInputChange(THERMAL_PRINTER_NAME_FIELD);
                    }}
                  />

                  <button
                    className={styles.button}
                    onClick={
                      isPrinterChosen ? onTestPrinter : onConfigurePrinter
                    }
                  >
                    {isPrinterChosen ? TEST_PRINTER : CONFIGURE_USB_PRINTER}
                  </button>
                </div>
                <Field
                  name={THERMAL_PRINTER_ASSET_NUMBER_FIELD}
                  component={InputWithTitle}
                  label={THERMAL_PRINTER_ASSET_NUMBER}
                  required
                  disabled={isMissingAssetNumberChecked}
                  maxLength={50}
                />
                <Field
                  name={MISSING_ASSET_NUMBER_FIELD}
                  component={CheckBoxInput}
                  label={IF_UNABLE_READ_ASSET_NUMBER}
                  onChange={(e, newValue) =>
                    onChangeAssetNumber(newValue ? MISSING_ASSET_NUMBER : "")
                  }
                />

                <section className={styles.printerTipSection}>
                  <div className={styles.tipImages}>
                    <img src={AssetTracking} alt={ASSET_TRACKING} />
                    <img
                      src={AssetTrackingDpdgroup}
                      alt={ASSET_TRACKING_DPD}
                      className={styles.dpdAssetNumber}
                    />
                  </div>
                  <div className={styles.tipText}>
                    {THERMAL_PRINTER_CAN_BE_FOUND}
                  </div>
                </section>
              </>
            )}
          </>
        )}

        <SubTitle title={LASER_PRINTER_SETTINGS} />
        <section className={styles.laserPrinterSection}>
          <CustomLabel label={LABELS_PER_PAGE} />
          <Field
            name={LABELS_PER_PAGE_FIELD}
            component={RadioInput}
            label={LABELS_PER_PAGE_RADIO_ONE}
            value={"1"}
            type="radio"
            classNameLabel={styles.radioLabel}
            onChange={() => {
              onInputChange(LABELS_PER_PAGE_RADIO_ONE);
            }}
          />
          <Field
            name={LABELS_PER_PAGE_FIELD}
            component={RadioInput}
            label={LABELS_PER_PAGE_RADIO_TWO}
            value={"2"}
            type="radio"
            classNameLabel={styles.radioLabel}
            onChange={() => {
              onInputChange(LABELS_PER_PAGE_RADIO_TWO);
            }}
          />
        </section>
        {isFromElectron && showMyDPDAppMigration && <MyDPDCustomerCard />}
      </InnerLayout>
      <FormBottomSection
        onSave={handleSubmit(onSave)}
        message={message}
        disabled={!isPrintingFormValid}
      />
    </div>
  );
};

PrintingSettings.propTypes = {
  handleSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onTestPrinter: PropTypes.func,
  onInputChange: PropTypes.func,
  onInitializeForm: PropTypes.func,
  onChangeAssetNumber: PropTypes.func,
  onConfigurePrinter: PropTypes.func,
  message: PropTypes.string,
  showMyDPDAppMigration: PropTypes.bool,
};

const mapStateToProps = state => ({
  message: getUserErrorMessage(state),
  showMyDPDAppMigration: shouldShowMyDPDCustomerAppMigration(state),
});

const mapDispatchToProps = dispatch => ({
  savePrintingSettings: values => dispatch(savePrintingSettings(values)),
  onInitializeForm: () => dispatch(initializeForm()),
  onTestPrinter: () => dispatch(testPrinter()),
  configurePrinter: () => dispatch(configurePrinter()),
  changePrintingSettingsFieldValue: (field, value) =>
    dispatch(changePrintingSettingsFieldValue(field, value)),
});

export default compose(
  reduxForm({
    form: PRINTING_SETTING_FORM,
    validate,
  }),
  withTrack({
    loadId: PRINTING_SETTINGS_IDS.LOAD,
    interfaceId: PRINTING_SETTINGS_IDS.INTERFACE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(props => ({
    onSave: values => {
      props.savePrintingSettings(values);
      return PRINTING_SETTINGS_IDS.CLICK_SAVE;
    },
    onInputChange: type => {
      switch (type) {
        case PRINT_TO_THERMAL_FIELD:
          return PRINTING_SETTINGS_IDS.CLICK_CHECKBOX_PRINTING_TO_THERMAL_PRINTER;
        case THERMAL_PRINTER_TYPE_FIELD:
          return PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_TYPE;
        case THERMAL_PRINTER_NAME_FIELD:
          return PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_NAME;
        case LABELS_PER_PAGE_RADIO_TWO:
        case LABELS_PER_PAGE_RADIO_ONE:
          return PRINTING_SETTINGS_IDS.CLICK_RADIO_BUTTON_LABELS_PER_PAGE;
      }
    },
    onChangeAssetNumber: newValue => {
      props.changePrintingSettingsFieldValue(
        THERMAL_PRINTER_ASSET_NUMBER_FIELD,
        newValue
      );
      return PRINTING_SETTINGS_IDS.CLICK_CHECKBOX_ASSET_NUMBER;
    },
    onConfigurePrinter: () => {
      props.configurePrinter();
      return PRINTING_SETTINGS_IDS.CLICK_BUTTON_CONFIGURE_USB_PRINTER;
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitializeForm();
    },
  })
)(PrintingSettings);
