export const MAIN_MENU = {
  CLICK_COLLECTION_DEFAULTS: "BB1Xz7aFYFxqYrUx",
  CLICK_CONTACT_DETAILS: "cu1Xz7aFYFxqYrUL",
  CLICK_INVOICE_SETTINGS: "Zbbfz7aFYFxqYr2y",
  CLICK_PRINTING_SETTINGS: "Te1Xz7aFYFxqYrUe",
  CLICK_SHIPPING_DEFAULTS: "7Ehfz7aFYFxqYr11",
};

export const CONTACT_DETAILS_IDS = {
  INTERFACE_ID: "0EF.z7aFYFxqYoYH",
  CLICK_CONTACT_DETAILS: "0EF.z7aFYFxqYoYH",
  CLICK_DROPDOWN_LIST_TITLE: "oqSz77aFYFxqYnzm",
  CLICK_SAVE: "8QFAL7aFYFxqYsN5",
  LOAD: ".21tz7aFYFxqYqZN",
};

export const COLLECTION_DEFAULTS_IDS = {
  INTERFACE_ID: "YA5Jv7aFYFxqYtoV",
  CLICK_COLLECTION_DEFAULTS: "YA5Jv7aFYFxqYtoV",
  CLICK_DROPDOWN_LIST_PRODUCT: "Wg4zv7aFYFxqYgdM",
  CLICK_DROPDOWN_LIST_SERVICE: "HXjzv7aFYFxqYggX",
  CLICK_SAVE: "A_Yzv7aFYFxqYgcw",
  LOAD: "vXYzv7aFYFxqYgcC",
};

export const INVOICE_SETTINGS_IDS = {
  INTERFACE_ID: "T.zzP7aFYFxqYpLa",
  CLICK_INVOICE_SETTINGS: "T.zzP7aFYFxqYpLa",
  CLICK_DROPDOWN_LIST_INVOICE_SELECTION: "iR83P7aFYFxqYrBT",
  CLICK_DROPDOWN_LIST_INVOICE_TYPE: "1oWPP7aFYFxqYrD7",
  CLICK_SAVE: "De83P7aFYFxqYrA.",
  LOAD: "d683P7aFYFxqYrAV",
};

export const PRINTING_SETTINGS_IDS = {
  INTERFACE_ID: "1r.8WnaFYFxqrJQJ",
  CLICK_PRINTING_SETTINGS: "1r.8WnaFYFxqrJQJ",
  CLICK_BUTTON_CONFIGURE_USB_PRINTER: "Yrui.naFYFxqRihY",
  CLICK_CHECKBOX_ASSET_NUMBER: "cggU.naFYFxqRhT7",
  CLICK_CHECKBOX_PRINTING_TO_THERMAL_PRINTER: "BIdE.naFYFxqRgxn",
  CLICK_DROPDOWN_LIST_PRINTER_NAME: "Brws.naFYFxqRh2D",
  CLICK_DROPDOWN_LIST_PRINTER_TYPE: "nlSs.naFYFxqRh3i",
  CLICK_RADIO_BUTTON_LABELS_PER_PAGE: "H69S.naFYFxqRiuJ",
  CLICK_SAVE: "7fBe.naFYFxqRsSI",
  LOAD: "LPpLWnaFYFxqRtVy",
};

export const SHIPPING_SETTINGS_IDS = {
  INTERFACE_ID: "o2sA6HaFYFxqaIlS",
  CLICK_SHIPPING_DEFAULTS: "o2sA6HaFYFxqaIlS",
  CLICK_CHECKBOX_ALLOW_PARCELS_TO_BE_SHIPPED: "gCmi6HaFYFxqaLqx",
  CLICK_CHECKBOX_SENDERS_ADDRESS_ON_LABEL: "FIrS6HaFYFxqaL20",
  CLICK_DEFAULT_SHIPMENT_DATE: "TgEo6HaFYFxqaJzh",
  CLICK_DROPDOWN_LIST_ADDRESSBOOK_TYPE: "hu3Q6HaFYFxqaJvd",
  CLICK_DROPDOWN_LIST_COUNTRY: "ZUOQ6HaFYFxqaJks",
  CLICK_DROPDOWN_LIST_CURRENCY: "TRqk6HaFYFxqaKDJ",
  CLICK_DROPDOWN_LIST_EXTENDED_LIABILITY: "p3NE6HaFYFxqaKBw",
  CLICK_DROPDOWN_LIST_PRODUCT: "p9zA6HaFYFxqaJSX",
  CLICK_DROPDOWN_LIST_PRODUCT_SERVICE: "rd0E6HaFYFxqaKAc",
  CLICK_DROPDOWN_LIST_SERVICE: "TjzA6HaFYFxqaJSz",
  CLICK_RADIO_BUTTON_AUTO_PRINT: "p9Sy6HaFYFxqaMBs",
  CLICK_RADIO_BUTTON_TOTAL_NO_OF_PACKAGES: "MV6K6HaFYFxqaMPf",
  CLICK_SAVE: "AtzA6HaFYFxqaJRs",
  LOAD: "OlzA6HaFYFxqaJQs",
};

export const PRIMARY_MENU = {
  CLICK_ADDRESS_BOOK: "8koVq3aFYFxqn2J8",
  CLICK_COLLECTIONS: "opF5q3aFYFxqn1n.",
  CLICK_DPD_LOGO: "7RwpW3aFYFxqn6Sc",
  CLICK_DELIVERIES: "bVF5q3aFYFxqn1pA",
  CLICK_DEPOT_FINDER: "xFuVq3aFYFxqn2K0",
  CLICK_HELP: "Q4JxW3aFYFxqn6Fs",
  CLICK_LOG_OFF: "R9KJW3aFYFxqn6HG",
  CLICK_MYDPD: "hZF5q3aFYFxqn1oU",
  CLICK_SEARCH: "CT0q5Y6C48kIGFGh",
  CLICK_SHIPPING: "7FF5q3aFYFxqn1oq",
  CLICK_SHOP_RETURNS: "cxF5q3aFYFxqn1nu",
};

export const SECONDARY_MENU = {
  CLICK_API: "Lgpdq3aFYFxqn3ZG",
  CLICK_DIAGNOSTICS: "Copdq3aFYFxqn3Zo",
  CLICK_MY_ACCOUNT_DETAILS: "8Apdq3aFYFxqn3Y0",
  CLICK_REPORTS: "pEpdq3aFYFxqn3aM",
  CLICK_WELCOME: "lwpdq3aFYFxqn3ZY",
};

export const HOME_PAGE = {
  INTERFACE_ID: "qw5Uw_aFYFxo5kzx",
  LOAD: "ovj0w_aFYFxo5k2f",
};
