import api from "../../utils/api";
import { API_ENDPOINTS } from "../../constants/common";

export const fetchConfig = () => api.get(`${API_ENDPOINTS.CONFIG}`);

export const getCurrencies = () =>
  api.get(`${API_ENDPOINTS.REFERENCE}/currencies`);

export const getDpdServices = () =>
  api.get({
    path: `${API_ENDPOINTS.CONFIG}/dpd-services`,
  });

export const fetchServices = query =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-services`,
    query,
  });

export const fetchProducts = query =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-products`,
    query,
  });

export const fetchNetworks = () =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-networks`,
  });
