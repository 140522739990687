import React from "react";
import { Router } from "react-router";

import Layout from "../components/Layout/Layout";
import MenuComponent from "../components/Layout/components/MenuComponent";
import { history } from "./history";
import { MAIN_PAGE } from "./constants";
import AuthRoute from "./authRoute";
import { PreferencesRoutes } from "./preferencesRoute";

export const Routes = () => (
  <Router history={history}>
    <Layout MenuComponent={MenuComponent}>
      <AuthRoute path={MAIN_PAGE} component={PreferencesRoutes} />
    </Layout>
  </Router>
);
