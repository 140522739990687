import { change, initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getCurrentUser, updateSettings } from "../user/user.actions";
import { SHIPPING_SETTING_FORM } from "../../constants/forms";
import {
  DEFAULT_SHIPMENT_DATE_FIELD,
  SHIPPING_DEFAULTS,
} from "./shippingSettings.constants";
import {
  getWeekendNextMonday,
  mapDataToForm,
  prepareData,
} from "./shippingSettings.model";

const ACTION_NAMESPACE = "SHIPPING_SETTINGS_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  INITIALIZE_FORM: createAsyncActionTypes("INITIALIZE_FORM"),
  UPDATE_SHIPPING_SETTINGS: createAsyncActionTypes("UPDATE_SHIPPING_SETTINGS"),
});

export const initializeForm = createAsyncAction(
  () => async dispatch => {
    const user = await dispatch(getCurrentUser());
    dispatch(initialize(SHIPPING_SETTING_FORM, mapDataToForm({ user })));
  },
  ACTIONS.INITIALIZE_FORM
);

export const changeShipmentFieldValue = (fieldName, value) =>
  change(SHIPPING_SETTING_FORM, fieldName, value);

export const updateShippingSettings = createAsyncAction(
  formValues => dispatch => {
    const values = prepareData(formValues);
    return dispatch(updateSettings({ [SHIPPING_DEFAULTS]: values }));
  },
  ACTIONS.UPDATE_SHIPPING_SETTINGS
);

export const changeDateOnAllowWeekendUncheck = shippingDate => dispatch => {
  const date = getWeekendNextMonday(shippingDate);
  dispatch(changeShipmentFieldValue(DEFAULT_SHIPMENT_DATE_FIELD, date));
};
