import { initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getCurrentUser, updateSettings } from "../user/user.actions";
import { INVOICE_SETTINGS_FORM } from "../../constants/forms";
import { mapDataToForm, prepareData } from "./invoiceSettings.model";
import { PREFS_INVOICE_SETTINGS } from "./invoiceSettings.constants";

const ACTION_NAMESPACE = "INVOICE_SETTINGS_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  INITIALIZE_FORM: createAsyncActionTypes("INITIALIZE_FORM"),
  UPDATE_INVOICE_SETTINGS: createAsyncActionTypes("UPDATE_INVOICE_SETTINGS"),
});

export const initializeForm = createAsyncAction(
  () => async dispatch => {
    const user = await dispatch(getCurrentUser());
    dispatch(initialize(INVOICE_SETTINGS_FORM, mapDataToForm({ user })));
  },
  ACTIONS.INITIALIZE_FORM
);

export const updateInvoiceSettings = createAsyncAction(
  formValues => dispatch => {
    const values = prepareData(formValues);
    return dispatch(updateSettings({ [PREFS_INVOICE_SETTINGS]: values }));
  },
  ACTIONS.UPDATE_INVOICE_SETTINGS
);
