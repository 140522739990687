import firebase from "firebase/app";
import { FirebaseTracker } from "@dpdgroupuk/react-event-tracker";

export const setUserProperties = user => {
  // firebase.analytics().setUserId(user.uid);
  // firebase.analytics().setUserProperties(user);
};

export default new FirebaseTracker(firebase, {
  projectRef: "CUP",
  debug: true,
});
