import get from "lodash/get";

import {
  DEFAULT_PRODUCT,
  DEFAULT_SERVICE_FIELD,
  FIELDS,
  NUMBER_FIELDS,
  PRE_ARRANGED,
  PREFS_COLLECTIONS_DEFAULTS,
} from "./collectionDefaults.constants";
import { getAnyway } from "../../utils/objectUtils";

export const mapDataToForm = ({ user }) =>
  FIELDS.reduce((acc, field) => {
    acc[field] = get(
      user,
      `${PREFS_COLLECTIONS_DEFAULTS}.${field}`,
      field === PRE_ARRANGED ? false : ""
    );
    return acc;
  }, {});

export const prepareData = formValues => {
  return Object.keys(formValues).reduce((acc, field) => {
    if (NUMBER_FIELDS.includes(field)) {
      acc[field] = Number(get(formValues, field, 0)) || null;
    } else if (field === DEFAULT_PRODUCT || field === DEFAULT_SERVICE_FIELD) {
      acc[field] = getAnyway(formValues, field);
    } else {
      acc[field] = formValues[field];
    }

    return acc;
  }, {});
};
