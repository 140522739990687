import { get } from "lodash";

export default errorHandler => store => next => action => {
  try {
    const error = get(action.payload, "error", null);
    if (error) {
      return errorHandler(error, store.getState, next, action, store.dispatch);
    }
    return next(action);
  } catch (err) {
    errorHandler(err, store.getState, action, store.dispatch);
    return err;
  }
};
