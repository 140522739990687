import React from "react";
import PropTypes from "prop-types";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { compose } from "recompose";

import styles from "./Header.module.scss";
import { HELP, HOME, LOGOUT } from "../../../constants/strings";
import SecondaryMenu from "./SecondaryMenu";
import PrimaryMenu from "./PrimaryMenu";
import Link from "./Link";
import { WithTheme } from "../../../hocs/withTheme";
import { getDomainLink } from "../../../utils/domain";
import { PRIMARY_MENU } from "../../../constants/analytics";

const Header = ({ theme, onClickHelp, onClickLogOff, onLogoClick }) => {
  const domain = getDomainLink();
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <a href={domain} title={HOME} onClick={onLogoClick}>
          <img src={theme.logoImg} width="96px" alt="Site Logo" />
        </a>
      </div>
      <div className={styles.top_container}>
        <div className={styles.utilities}>
          <Link
            href={"http://help.dpd.co.uk/mydpd/"}
            name={HELP}
            onClick={onClickHelp}
          />
          <span> | </span>
          <Link
            href={`${domain}/umslogon/public/logoff.do`}
            name={LOGOUT}
            onClick={onClickLogOff}
          />
        </div>
        <PrimaryMenu />
        <SecondaryMenu />
      </div>
    </div>
  );
};

Header.propTypes = {
  theme: PropTypes.object,
  onClickHelp: PropTypes.func,
  onClickLogOff: PropTypes.func,
  onLogoClick: PropTypes.func,
};

export default compose(
  WithTheme,
  withTrackProps(() => ({
    onClickHelp: () => PRIMARY_MENU.CLICK_HELP,
    onClickLogOff: () => PRIMARY_MENU.CLICK_LOG_OFF,
    onLogoClick: () => PRIMARY_MENU.CLICK_DPD_LOGO,
  }))
)(Header);
