import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./InvoiceSettings.module.scss";
import { INVOICE_SETTINGS_FORM } from "../../constants/forms";
import InputWithTitle from "../../components/InputWithTitle/InputWithTitle";
import SubTitle from "../../components/Title/SubTitle";
import Title from "../../components/Title/Title";
import {
  ADDRESS_1_FIELD,
  ADDRESS_2_FIELD,
  ADDRESS_3_FIELD,
  ADDRESS_4_FIELD,
  COMPANY_NAME_FIELD,
  CONTACT_NAME_FIELD,
  CONTACT_NUMBER_FIELD,
  CREATE_INVOICE_VALUES,
  DESTINATION_TAX_ID_NUMBER_FIELD,
  EORI_NUMBER_FIELD,
  FDA_REGISTRATION_NUMBER_FIELD,
  INVOICE_SELECTION_FIELD,
  INVOICE_TYPE_FIELD,
  INVOICE_TYPE_VALUES,
  PAGE_HEADER_FIELD,
  POSTCODE_FIELD,
  PROPERTY_FIELD,
  VAT_NUMBER_FIELD,
} from "../../features/invoiceSettings/invoiceSettings.constants";
import {
  ADDRESS_1,
  ADDRESS_2,
  ADDRESS_3,
  ADDRESS_4,
  COMPANY_NAME,
  CONTACT_NAME,
  CONTACT_NUMBER,
  DESTINATION_TAX_ID_NUMBER,
  EORI_NUMBER,
  ERROR_SAVING_MSG,
  FDA_REGISTRATION_NUMBER,
  INVOICE_DETAILS,
  INVOICE_SELECTION,
  INVOICE_SETTINGS_MSG,
  INVOICE_TYPE,
  LIMIT_CHARACTERS_MSG,
  PAGE_HEADER,
  POSTCODE,
  PROPERTY,
  SENDER_INVOICE_DETAILS,
  VAT_NUMBER,
} from "../../constants/strings";
import InnerLayout from "../../components/Layout/InnerLayout";
import FormBottomSection from "../../components/formBottomSection/FormBottomSection";
import {
  initializeForm,
  updateInvoiceSettings,
} from "../../features/invoiceSettings/invoiceSettings.actions";
import { getUserErrorMessage } from "../../features/user/user.selectors";
import SelectFormField from "../../components/select/SelectFormField";
import { INVOICE_SETTINGS_IDS } from "../../constants/analytics";

const InvoiceSettings = ({ handleSubmit, onInputChange, onSave, message }) => {
  if (message === ERROR_SAVING_MSG) {
    message = `${message} ${INVOICE_SETTINGS_MSG}`;
  }
  return (
    <div className={styles.container}>
      <Title title={INVOICE_DETAILS} />
      <InnerLayout>
        <Field
          component={SelectFormField}
          label={INVOICE_SELECTION}
          name={INVOICE_SELECTION_FIELD}
          values={CREATE_INVOICE_VALUES}
          onChange={() => {
            onInputChange(INVOICE_SELECTION_FIELD);
          }}
        />
        <Field
          component={SelectFormField}
          label={INVOICE_TYPE}
          name={INVOICE_TYPE_FIELD}
          values={INVOICE_TYPE_VALUES}
          onChange={() => {
            onInputChange(INVOICE_TYPE_FIELD);
          }}
        />
        <Field
          name={EORI_NUMBER_FIELD}
          component={InputWithTitle}
          label={EORI_NUMBER}
          maxLength={25}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={VAT_NUMBER_FIELD}
          component={InputWithTitle}
          label={VAT_NUMBER}
          maxLength={25}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={DESTINATION_TAX_ID_NUMBER_FIELD}
          component={InputWithTitle}
          label={DESTINATION_TAX_ID_NUMBER}
          maxLength={25}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={FDA_REGISTRATION_NUMBER_FIELD}
          component={InputWithTitle}
          label={FDA_REGISTRATION_NUMBER}
          maxLength={25}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={PAGE_HEADER_FIELD}
          component={InputWithTitle}
          label={PAGE_HEADER}
          subLabel={`(${LIMIT_CHARACTERS_MSG})`}
          classNameLabel={styles.label}
          classNameField={styles.field}
          multiline
          rows="3"
          maxLength={255}
          classNameContainer={styles.fieldContainer}
        />
        <SubTitle title={SENDER_INVOICE_DETAILS} />
        <Field
          name={COMPANY_NAME_FIELD}
          component={InputWithTitle}
          label={COMPANY_NAME}
          maxLength={100}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={PROPERTY_FIELD}
          component={InputWithTitle}
          label={PROPERTY}
          maxLength={35}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={ADDRESS_1_FIELD}
          component={InputWithTitle}
          label={ADDRESS_1}
          maxLength={100}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={ADDRESS_2_FIELD}
          component={InputWithTitle}
          label={ADDRESS_2}
          maxLength={100}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={ADDRESS_3_FIELD}
          component={InputWithTitle}
          label={ADDRESS_3}
          maxLength={100}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={ADDRESS_4_FIELD}
          component={InputWithTitle}
          label={ADDRESS_4}
          maxLength={100}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={POSTCODE_FIELD}
          component={InputWithTitle}
          label={POSTCODE}
          maxLength={8}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={CONTACT_NAME_FIELD}
          component={InputWithTitle}
          label={CONTACT_NAME}
          maxLength={35}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
        <Field
          name={CONTACT_NUMBER_FIELD}
          component={InputWithTitle}
          label={CONTACT_NUMBER}
          maxLength={50}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
      </InnerLayout>
      <FormBottomSection onSave={handleSubmit(onSave)} message={message} />
    </div>
  );
};

InvoiceSettings.propTypes = {
  handleSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
};

const mapStateToProps = state => ({
  message: getUserErrorMessage(state),
});

const mapDispatchToProps = dispatch => ({
  updateInvoiceSettings: values => dispatch(updateInvoiceSettings(values)),
  onInitializeForm: () => dispatch(initializeForm()),
});

export default compose(
  reduxForm({
    form: INVOICE_SETTINGS_FORM,
  }),
  withTrack({
    loadId: INVOICE_SETTINGS_IDS.LOAD,
    interfaceId: INVOICE_SETTINGS_IDS.INTERFACE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(props => ({
    onSave: values => {
      props.updateInvoiceSettings(values);
      return INVOICE_SETTINGS_IDS.CLICK_SAVE;
    },
    onInputChange: type => {
      switch (type) {
        case INVOICE_SELECTION_FIELD:
          return INVOICE_SETTINGS_IDS.CLICK_DROPDOWN_LIST_INVOICE_SELECTION;
        case INVOICE_TYPE_FIELD:
          return INVOICE_SETTINGS_IDS.CLICK_DROPDOWN_LIST_INVOICE_TYPE;
      }
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitializeForm();
    },
  })
)(InvoiceSettings);
