import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as configService from "./config.service";

const ACTION_NAMESPACE = "CONFIG";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_CONFIG: createAsyncActionTypes("FETCH_CONFIG"),
  FETCH_DPD_SERVICES: createAsyncActionTypes("FETCH_DPD_SERVICES"),
  FETCH_CURRENCIES: createAsyncActionTypes("FETCH_CURRENCIES"),
  FETCH_NETWORKS: createAsyncActionTypes("FETCH_NETWORKS"),
  FETCH_PRODUCTS: createAsyncActionTypes("FETCH_PRODUCTS"),
  FETCH_SERVICES: createAsyncActionTypes("FETCH_SERVICES"),
});

export const fetchConfig = createAsyncAction(
  () => configService.fetchConfig(),
  ACTIONS.FETCH_CONFIG
);

export const fetchCurrencies = createAsyncAction(
  () => configService.getCurrencies(),
  ACTIONS.FETCH_CURRENCIES
);

export const fetchDpdServices = createAsyncAction(
  () => configService.getDpdServices(),
  ACTIONS.FETCH_DPD_SERVICES
);

export const fetchProducts = createAsyncAction(
  configService.fetchProducts,
  ACTIONS.FETCH_PRODUCTS
);

export const fetchServices = createAsyncAction(
  configService.fetchServices,
  ACTIONS.FETCH_SERVICES
);

export const fetchNetworks = createAsyncAction(
  configService.fetchNetworks,
  ACTIONS.FETCH_NETWORKS
);
