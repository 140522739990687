import get from "lodash/get";
import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { PRINTING_SETTING_FORM } from "../../constants/forms";
import {
  MISSING_ASSET_NUMBER_FIELD,
  PRINT_TO_THERMAL_FIELD,
  THERMAL_PRINTER_NAME_FIELD,
  THERMAL_PRINTER_TYPE_FIELD,
  THERMAL_PRINTER_TYPES,
} from "./printingSettings.constants";
import { SELECT_PRINTER } from "../../constants/strings";
import { getUserShippingVersion } from "../user/user.selectors";
import { shouldMyDPDCustomerAppBeShown } from "./printingSettings.model";

export const getPrintingSettingsFormValues = getFormValues(
  PRINTING_SETTING_FORM
);

export const getPrintToThermal = createSelector(
  getPrintingSettingsFormValues,
  formValues => get(formValues, PRINT_TO_THERMAL_FIELD, false)
);

export const getMissingAssetNumberFieldValue = createSelector(
  getPrintingSettingsFormValues,
  formValues => get(formValues, MISSING_ASSET_NUMBER_FIELD, false)
);

export const getLabelsPerPage = createSelector(
  getPrintingSettingsFormValues,
  ({ labelsPerPage }) => labelsPerPage
);

export const getThermalPrinterName = createSelector(
  getPrintingSettingsFormValues,
  formValues => get(formValues, THERMAL_PRINTER_NAME_FIELD, null)
);

export const getThermalPrinterType = createSelector(
  getPrintingSettingsFormValues,
  formValues =>
    get(formValues, THERMAL_PRINTER_TYPE_FIELD, THERMAL_PRINTER_TYPES[0].value)
);

export const getSelectedPrinterDevice = createSelector(
  getThermalPrinterName,
  name => ({ name })
);

export const isPrinterSelected = createSelector(
  getThermalPrinterName,
  printerName => !!printerName && printerName !== SELECT_PRINTER
);

export const getPrintingSettings = state => state.printingSettings;

export const getNewPrinterSettings = createSelector(
  getPrintingSettings,
  printingSettings => printingSettings.settings
);

export const isMyDPDCustomerAppRunning = createSelector(
  getPrintingSettings,
  printingSettings => printingSettings.isMyDPDRunning
);

export const shouldShowMyDPDCustomerAppMigration = createSelector(
  getUserShippingVersion,
  getNewPrinterSettings,
  shouldMyDPDCustomerAppBeShown
);
