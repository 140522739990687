import FetchClient from "@dpdgroupuk/fetch-client";

import { isDpdLocalDomain } from "./domain";
const {
  REACT_APP_API_LOCAL_DPD_DOMAIN,
  REACT_APP_API_DPD_DOMAIN,
  REACT_APP_API_DOMAIN,
} = process.env;

const getAppApiDomain = () =>
  (isDpdLocalDomain()
    ? REACT_APP_API_LOCAL_DPD_DOMAIN
    : REACT_APP_API_DPD_DOMAIN) || REACT_APP_API_DOMAIN;

const api = new FetchClient({
  credentials: "include",
  baseUrl: `${getAppApiDomain()}`,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: -1,
  },
});

export default api;
