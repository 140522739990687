import React from "react";
import PropTypes from "prop-types";

import styles from "./FormBottomSection.module.scss";
import WarningText from "../WarningText/WarningText";
import { SAVE } from "../../constants/strings";

const FormBottomSection = ({ onSave, message, disabled }) => {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.bottonWraper}>
        {!!message && <WarningText message={message} />}
        <button onClick={onSave} disabled={disabled}>
          {SAVE}
        </button>
      </div>
    </div>
  );
};

FormBottomSection.propTypes = {
  message: PropTypes.string,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormBottomSection;
