import get from "lodash/get";

import {
  FIELDS,
  INVOICE_SELECTION_FIELD,
  INVOICE_TYPE_FIELD,
  PREFS_INVOICE_SETTINGS,
} from "../invoiceSettings/invoiceSettings.constants";
import { getAnyway } from "../../utils/objectUtils";

export const mapDataToForm = ({ user }) => {
  return FIELDS.reduce((acc, field) => {
    if (field === INVOICE_SELECTION_FIELD || field === INVOICE_TYPE_FIELD) {
      acc[field] = get(user, `${PREFS_INVOICE_SETTINGS}.${field}`, "S");
    } else {
      acc[field] = get(user, `${PREFS_INVOICE_SETTINGS}.${field}`, null);
    }

    return acc;
  }, {});
};

export const prepareData = formValues => {
  return Object.keys(formValues).reduce((acc, field) => {
    acc[field] = getAnyway(formValues, field);
    return acc;
  }, {});
};
