import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import get from "lodash/get";

import { SHIPPING_SETTING_FORM } from "../../constants/forms";
import {
  DEFAULT_SHIPMENT_DATE_FIELD,
  ENABLE_WEEKEND_FIELD,
} from "./shippingSettings.constants";

const getShippingSettingsFormValues = getFormValues(SHIPPING_SETTING_FORM);

export const getEnableWeekend = createSelector(
  getShippingSettingsFormValues,
  formValues => get(formValues, ENABLE_WEEKEND_FIELD)
);

export const getShippingDate = createSelector(
  getShippingSettingsFormValues,
  formValues => get(formValues, DEFAULT_SHIPMENT_DATE_FIELD, new Date())
);
