import { createSelector } from "reselect";

import {
  prepareSelectData,
  prepareSortedCountriesList,
} from "./country.models";

export const getCountries = state => state.country.countries;

export const getCountryOptions = createSelector(getCountries, countries =>
  prepareSelectData(countries)
);

export const getCountriesSortedList = createSelector(
  getCountryOptions,
  prepareSortedCountriesList
);
