import { initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getCurrentUser, updateSettings } from "../user/user.actions";
import { COLLECTION_DETAILS_FORM } from "../../constants/forms";
import { mapDataToForm, prepareData } from "./collectionDefaults.model";
import { PREFS_COLLECTIONS_DEFAULTS } from "./collectionDefaults.constants";

const ACTION_NAMESPACE = "COLLECTION_DEFAULTS_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  INITIALIZE_FORM: createAsyncActionTypes("INITIALIZE_FORM"),
  UPDATE_COLLECTION: createAsyncActionTypes("UPDATE_COLLECTION"),
});

export const initializeForm = createAsyncAction(
  () => async dispatch => {
    const user = await dispatch(getCurrentUser());
    dispatch(initialize(COLLECTION_DETAILS_FORM, mapDataToForm({ user })));
  },
  ACTIONS.INITIALIZE_FORM
);

export const updateCollectionDefaults = createAsyncAction(
  formValues => dispatch => {
    const values = prepareData(formValues);
    return dispatch(updateSettings({ [PREFS_COLLECTIONS_DEFAULTS]: values }));
  },
  ACTIONS.UPDATE_COLLECTION
);
