import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./HintLabel.module.scss";
import { MANDATORY } from "../../constants/strings";

const HintLabel = ({ label, containerClassName, labelClassName }) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <span className={styles.mandatory}>*</span>
      <span className={classNames(styles.label, labelClassName)}>{label}</span>
    </div>
  );
};

HintLabel.defaultProps = {
  label: MANDATORY,
};

HintLabel.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default HintLabel;
