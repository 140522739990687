import {
  PRINT_TO_THERMAL_FIELD,
  THERMAL_PRINTER_ASSET_NUMBER_FIELD,
  THERMAL_PRINTER_NAME_FIELD,
} from "./printingSettings.constants";
import { FIELD_IS_MANDATORY } from "../../constants/strings";

export const validate = props => {
  const errors = {};

  if (props[PRINT_TO_THERMAL_FIELD]) {
    if (!props[THERMAL_PRINTER_NAME_FIELD]) {
      errors[THERMAL_PRINTER_NAME_FIELD] = FIELD_IS_MANDATORY;
    }

    if (!props[THERMAL_PRINTER_ASSET_NUMBER_FIELD]) {
      errors[THERMAL_PRINTER_ASSET_NUMBER_FIELD] = FIELD_IS_MANDATORY;
    }
  }

  return errors;
};
