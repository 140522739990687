import api from "../../utils/api";
import { API_ENDPOINTS } from "../../constants/common";
import { getPreferencePage } from "./user.models";

export const getCurrentUser = () =>
  api
    .get({
      path: `${API_ENDPOINTS.USER}/me`,
    })
    .then(({ data }) => data);

export const updateUser = (uid, body) =>
  api.put({
    path: `${API_ENDPOINTS.USER}/${encodeURIComponent(uid)}`,
    query: {
      preferencePage: getPreferencePage(),
    },
    body,
  });
