import {
  DOM_NUM_ITEMS_FIELD,
  DOM_WEIGHT_FIELD,
  INT_DIM_HEIGHT_FIELD,
  INT_DIM_LENGTH_FIELD,
  INT_DIM_WEIGHT_FIELD,
  INT_DIM_WIDTH_FIELD,
  INT_LIABILITY_VALUE_FIELD,
  INT_NUM_ITEMS_FIELD,
  INT_WEIGHT_FIELD,
} from "./shippingSettings.constants";
import {
  MUST_BE_A_POSITIVE_NUMBER,
  MUST_BE_A_POSITIVE_WHOLE_NUMBER,
  MUST_BE_WITHIN_RANGE,
} from "../../constants/strings";
import { isNumber } from "./../../utils/validators";

const RANGE_VALIDATE_FIELDS = [
  INT_DIM_WIDTH_FIELD,
  INT_DIM_LENGTH_FIELD,
  INT_DIM_HEIGHT_FIELD,
];

const NUMBER_FIELDS = [
  INT_NUM_ITEMS_FIELD,
  DOM_NUM_ITEMS_FIELD,
  INT_LIABILITY_VALUE_FIELD,
];

const FLOAT_FIELDS = [INT_DIM_WEIGHT_FIELD, DOM_WEIGHT_FIELD, INT_WEIGHT_FIELD];

export const validate = (values, formProps) => {
  const errors = {};

  if (!formProps.initialized) {
    return errors;
  }

  const floatErrors = FLOAT_FIELDS.reduce((acc, key) => {
    const dirtyValue = values[key] && values[key].replace(",", ".");
    if (values[key] && !isNumber(dirtyValue)) {
      acc[key] = MUST_BE_A_POSITIVE_NUMBER;
    }
    return acc;
  }, {});

  const numberErrors = NUMBER_FIELDS.reduce((acc, key) => {
    const value = parseFloat(values[key], 10);
    if (values[key] && (isNaN(values[key]) || !Number.isInteger(value))) {
      acc[key] = MUST_BE_A_POSITIVE_WHOLE_NUMBER;
    }
    return acc;
  }, {});

  const invalidFields = RANGE_VALIDATE_FIELDS.reduce((acc, key) => {
    const value = parseInt(values[key], 10);
    if (value > 175 || value <= 0 || (isNaN(value) && values[key])) {
      acc[key] = MUST_BE_WITHIN_RANGE;
    }
    return acc;
  }, {});

  return { ...errors, ...invalidFields, ...numberErrors, ...floatErrors };
};
