import {
  COMMERCIAL,
  CREATE_OWN_INVOICE,
  GENERATE_INVOICE_ONLINE,
  PROFORMA,
  SELECT_EACH_TIME_INVOICE,
} from "../../constants/strings";

export const VAT_NUMBER_FIELD = "vatNumber";
export const DESTINATION_TAX_ID_NUMBER_FIELD = "destinationTaxIdRegNo";
export const FDA_REGISTRATION_NUMBER_FIELD = "fdaRegistrationNumber";
export const EORI_NUMBER_FIELD = "eoriNumber";
export const PAGE_HEADER_FIELD = "pageHeader";
export const INVOICE_SELECTION_FIELD = "createInvoice";
export const INVOICE_TYPE_FIELD = "invoiceType";
export const COMPANY_NAME_FIELD = "companyName";
export const PROPERTY_FIELD = "property";
export const ADDRESS_1_FIELD = "address1";
export const ADDRESS_2_FIELD = "address2";
export const ADDRESS_3_FIELD = "address3";
export const ADDRESS_4_FIELD = "address4";
export const POSTCODE_FIELD = "postcode";
export const CONTACT_NAME_FIELD = "contactName";
export const CONTACT_NUMBER_FIELD = "contactNumber";

export const PREFS_INVOICE_SETTINGS = "prefsInvoiceSettings";

export const FIELDS = [
  VAT_NUMBER_FIELD,
  PAGE_HEADER_FIELD,
  INVOICE_SELECTION_FIELD,
  INVOICE_TYPE_FIELD,
  COMPANY_NAME_FIELD,
  PROPERTY_FIELD,
  ADDRESS_1_FIELD,
  ADDRESS_2_FIELD,
  ADDRESS_3_FIELD,
  ADDRESS_4_FIELD,
  POSTCODE_FIELD,
  CONTACT_NAME_FIELD,
  CONTACT_NUMBER_FIELD,
  DESTINATION_TAX_ID_NUMBER_FIELD,
  FDA_REGISTRATION_NUMBER_FIELD,
  EORI_NUMBER_FIELD,
];

export const CREATE_INVOICE_VALUES = [
  { label: SELECT_EACH_TIME_INVOICE, value: "S" },
  { label: GENERATE_INVOICE_ONLINE, value: "G" },
  { label: CREATE_OWN_INVOICE, value: "C" },
];

export const INVOICE_TYPE_VALUES = [
  { label: SELECT_EACH_TIME_INVOICE, value: "S" },
  { label: COMMERCIAL, value: "C" },
  { label: PROFORMA, value: "P" },
];
