import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Link = ({ href, name, isActive, style, className, ...other }) => {
  return (
    <a
      key={href}
      href={href}
      className={classNames(
        className,
        style.link && style.link,
        isActive && style.active,
        isActive && "active"
      )}
      {...other}
    >
      {name}
    </a>
  );
};

Link.defaultProps = {
  isActive: false,
  style: {},
  className: "",
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Link;
