import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import Title from "../../components/Title/Title";
import InnerLayout from "../../components/Layout/InnerLayout";
import styles from "./ShippingSettings.module.scss";
import { SHIPPING_SETTING_FORM } from "../../constants/forms";
import {
  ERROR_SAVING_MSG,
  SHIPPING_DEFAULTS_MSG,
  SHIPPING_DETAILS,
} from "../../constants/strings";
import FormBottomSection from "../../components/formBottomSection/FormBottomSection";
import { getUserErrorMessage } from "../../features/user/user.selectors";
import DomesticShippingSettings from "./components/DomesticShippingSettings";
import DimensionShippingSettings from "./components/DimensionShippingSetting";
import InternationalShippingSettings from "./components/InternationalShippingSetting";
import GeneralShippingSettings from "./components/GeneralShippingSettings";
import ScanningShippingSettings from "./components/ScanningShippingSetting";
import HintLabel from "../../components/HintLabel/HintLabel";
import {
  initializeForm,
  updateShippingSettings,
} from "../../features/shippingSettings/shippingSettings.actions";
import { validate } from "../../features/shippingSettings/shippingSettings.validators";
import { SHIPPING_SETTINGS_IDS } from "../../constants/analytics";

const ShippingSettings = ({ handleSubmit, onSave, message }) => {
  if (message === ERROR_SAVING_MSG) {
    message = `${message} ${SHIPPING_DEFAULTS_MSG}`;
  }

  return (
    <div className={styles.container}>
      <Title title={SHIPPING_DETAILS} />
      <InnerLayout>
        <HintLabel />
        <GeneralShippingSettings />
        <DomesticShippingSettings />
        <InternationalShippingSettings />
        <DimensionShippingSettings />
        <ScanningShippingSettings />
      </InnerLayout>
      <FormBottomSection onSave={handleSubmit(onSave)} message={message} />
    </div>
  );
};

ShippingSettings.propTypes = {
  handleSubmit: PropTypes.func,
  onSave: PropTypes.func,
  message: PropTypes.string,
  onInitializeForm: PropTypes.func,
};

const mapStateToProps = state => ({
  message: getUserErrorMessage(state),
});

const mapDispatchToProps = dispatch => ({
  updateShippingSettings: values => dispatch(updateShippingSettings(values)),
  onInitializeForm: () => dispatch(initializeForm()),
});

export default compose(
  reduxForm({
    form: SHIPPING_SETTING_FORM,
    validate,
  }),
  withTrack({
    loadId: SHIPPING_SETTINGS_IDS.LOAD,
    interfaceId: SHIPPING_SETTINGS_IDS.INTERFACE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(props => ({
    onSave: values => {
      props.updateShippingSettings(values);
      return createLogEntryPayload(SHIPPING_SETTINGS_IDS.CLICK_SAVE);
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitializeForm();
    },
  })
)(ShippingSettings);
