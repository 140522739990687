import { get, isObject } from "lodash";

import { ACTIONS } from "./user.actions";
import { ACTIONS as APP_ACTIONS } from "../app/app.actions";
import { ACTIONS as AUTH_ACTIONS } from "../auth/auth.actions";
import {
  ERROR_SAVING_MSG,
  YOUR_CHANGES_HAVE_BEEN_SAVED,
} from "../../constants/strings";

export const initialState = {
  currentUser: null,
  message: "",
};

const getErrorMsg = e => {
  return isObject(e) ? get(e, "message", ERROR_SAVING_MSG) : e;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_USER.SUCCESS:
      return {
        ...state,
        message: YOUR_CHANGES_HAVE_BEEN_SAVED,
      };
    case ACTIONS.UPDATE_USER.FAILURE:
    case APP_ACTIONS.CONFIGURE_PRINTER.FAILURE:
    case APP_ACTIONS.CONFIGURE_PRINTER.SUCCESS:
      const { error, name, description } = action.payload;
      const successMessage =
        name && `Installed printer: ${description || name}`;
      const message = getErrorMsg(error) || successMessage || ERROR_SAVING_MSG;

      return {
        ...state,
        message,
      };
    case ACTIONS.FETCH_USER.SUCCESS:
      return {
        ...state,
        message: "",
        currentUser: action.payload,
      };
    case AUTH_ACTIONS.SIGN_OUT.SUCCESS:
      return initialState;
    default:
      return state;
  }
};
