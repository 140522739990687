import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/analytics";
import { ENVIRONMENTS } from "../constants/common";

const config = {
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

export const customerFirebase = firebase.initializeApp(
  {
    authDomain: process.env.REACT_APP_CUSTOMER_FIREBASE_AUTH_DOMAIN,
    apiKey: process.env.REACT_APP_CUSTOMER_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_CUSTOMER_FIREBASE_PROJECT_ID,
  },
  "customer"
);

if (process.env.NODE_ENV !== ENVIRONMENTS.PRODUCTION) {
  window.firebase = firebase;
  window.customerFirebase = customerFirebase;
}

export default firebase;
