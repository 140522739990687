import DPDLogo from "../assets/images/DPD_logo_96_x_43.png";
import DPDLocalLogo from "../assets/images/dpd-logo-local.png";
import colors from "../assets/stylesheets/modules/colors.scss";
import { MY_DPD, MY_DPD_LOCAL } from "./strings";

export const Theme = {
  dpd: {
    themeName: "red",
    logoImg: DPDLogo,
    homeMenuItemName: MY_DPD,
    palette: {
      main: colors.uiPrimaryDark,
      light: colors.uiPrimaryLight,
    },
  },
  dpdLocal: {
    themeName: "blue",
    logoImg: DPDLocalLogo,
    homeMenuItemName: MY_DPD_LOCAL,
    palette: {
      main: colors.uiLocalDark,
      light: colors.uiLocalLight,
    },
  },
};
