export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
};

export const getWeekends = (start, end) => {
  const result = [];
  while (start < end) {
    if (start.getDay() === 0 || start.getDay() === 6) {
      result.push(new Date(+start));
    }
    start = addDays(start, 1);
  }
  return result;
};
