export const COPYRIGHT =
  "Ⓒ 2019 DPD. DPD is a trading division of DPDgroup. Registered office: PO BOX 6979, Roebuck Lane, Smethwick, West Midlands, B66 1BN. Registered in England and Wales No. 732993";
export const MAIN_PAGE = "Main Page";
export const VAT_NUMBER = "VAT No";
export const DESTINATION_TAX_ID_NUMBER = "Destination Tax ID/Reg No";
export const FDA_REGISTRATION_NUMBER = "FDA Registration No";
export const EORI_NUMBER = "EORI No";
export const INVOICE_DETAILS = "Invoice Details";
export const COLLECTION_DETAILS = "Collection Details";
export const CONTACT_DETAILS = "Contact Details";
export const PRINTING_SETTINGS_DETAILS = "Printing Settings Details";
export const THERMAL_PRINTER_SETTINGS = "Thermal Printer Settings";
export const LASER_PRINTER_SETTINGS = "Laser Printer Settings";
export const MYDPD_CUSTOMER_SETTINGS = "MyDPD Customer";
export const SHIPPING_DETAILS = "Shipping Details";
export const COLLECTION_DEFAULT_DETAILS = "Collection Default Details";
export const NUMBER_OF_ITEMS = "Number of Items";
export const WEIGHT = "Weight";
export const KG = "Kg";
export const CLOSING_TIME = "Closing Time";
export const SENDER_INVOICE_DETAILS = "Senders Invoice Details";
export const COMPANY_NAME = "Company Name";
export const SAVE = "Save";
export const PROPERTY = "Property";
export const ADDRESS_1 = "Address 1";
export const ADDRESS_2 = "Address 2";
export const ADDRESS_3 = "Address 3";
export const ADDRESS_4 = "Address 4";
export const POSTCODE = "Postcode";
export const CONTACT_NAME = "Contact Name";
export const CONTACT_NUMBER = "Contact Number";
export const PAGE_HEADER = "Page Header";
export const YOUR_CHANGES_HAVE_BEEN_SAVED = "Your changes have been saved";
export const ERROR_SAVING_MSG = "Error saving";
export const INVOICE_SETTINGS_MSG = "invoice settings";
export const CONTACT_DETAILS_MSG = "contact details";
export const PRINTING_SETTINGS_MSG = "printing settings";
export const SHIPPING_DEFAULTS_MSG = "shipping defaults";
export const COLLECTION_DEFAULTS_MSG = "collection defaults";
export const ACCOUNT_NUMBER = "Account Number";
export const DOMESTIC_SHIPPING_DEFAULTS = "Domestic Shipping Defaults";
export const INTERNATIONAL_SHIPPING_DEFAULTS =
  "International Shipping Defaults";
export const DIMENSION_AIR_SERVICES_ONLY = "Dimension (Air services only)";
export const SCANNING_DEFAULTS = "Scanning Defaults";
export const USER_LOGIN_ID = "User Login ID";
export const PRODUCT = "Product";
export const SERVICE = "Service";
export const TITLE = "Title";
export const FORENAME = "Forename";
export const SURNAME = "Surname";
export const POSITION = "Position";
export const EMAIL = "Email";
export const TELEPHONE_NUMBER = "Telephone Number";
export const FIELD_CANT_BE_EMPTY = "{0} can not be empty";
export const MUST_BE_WITHIN_RANGE = "Must be within range of 1 to 175";
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^(\+)?(\d|\s)*$/;
export const PLEASE_ENTER_VALID_EMAIL = "Please enter a valid email";
export const INVALID_EMAIL = "Invalid Email";
export const INVALID_TELEPHONE_NUMBER = "Invalid Telephone Number";
export const INVOICE_SELECTION = "Invoice Selection";
export const INVOICE_TYPE = "Invoice Type";
export const GENERATE_INVOICE_ONLINE = "Generate Invoice Online";
export const CREATE_OWN_INVOICE = "Create Own Invoice";
export const OTHER = "Other";
export const COMMERCIAL = "Commercial";
export const PROFORMA = "Proforma";
export const SELECT_EACH_TIME_INVOICE = "Select each time invoice is required";
export const MUST_BE_A_POSITIVE_WHOLE_NUMBER =
  "Must be a positive whole number";
export const MUST_BE_A_POSITIVE_NUMBER = "Must be a positive number";
export const INVALID_TIME = "Invalid Time must be (HH:MM)";
export const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;

export const PRINTING_TO_THERMAL_PRINTER = "Printing to a Thermal Printer";
export const THERMAL_PRINTER_TYPE = "Thermal Printer Type";
export const THERMAL_PRINTER_NAME = "Thermal Printer Name";
export const CONFIGURE_USB_PRINTER = "Configure USB Printer";
export const TEST_PRINTER = "Test Printer";
export const THERMAL_PRINTER_ASSET_NUMBER = "Thermal Printer Asset Number";
export const IF_UNABLE_READ_ASSET_NUMBER =
  "If you are unable to find or cannot read your asset number, please tick this box";
export const LABELS_PER_PAGE = "Labels Per Page";
export const LABELS_PER_PAGE_RADIO_ONE = "1";
export const LABELS_PER_PAGE_RADIO_TWO = "2";
export const THERMAL_PRINTER_CAN_BE_FOUND =
  "The Thermal Printer Asset Number can be found on a sticker (see left) on the printer. The asset number is six numerical digits.";
export const MANDATORY = "Mandatory";
export const USE_SHARED_ADDRESS_BOOK = "Use shared address book";
export const COUNTRY = "Country";
export const DEFAULT_SHIPPING_ADDRESS_BOOK_SEARCH_TYPE =
  "Default shipping address book search type";
export const DEFAULT_SHIPMENT_DATE = "Default Shipment Date";
export const ALLOW_PARCELS_TO_BE_SHIPPED =
  "Allow parcels to be shipped on a Saturday/Sunday";
export const DEFAULT_INFORMATION = "Default Information {0} (Max. 50)";
export const WEIGHT_KG = "Weight (Kg)";
export const LENGTH_CM = "Length (cm)";
export const WIDTH_CM = "Width (cm)";
export const HEIGHT_CM = "Height (cm)";
export const SENDERS_ADDRESS_ON_LABEL = "Senders Address on Label";
export const REFERENCE_1_PREFIX = "Reference 1 Prefix";
export const DEFAULT_BULK_REVERSE_IT_REFERENCE =
  "Default Bulk Reverse-it Reference";
export const PRODUCT_SERVICE = "Products/Service";
export const EXTENDED_LIABILITY = "Extended Liability";
export const EXTENDED_LIABILITY_VALUE = "Extended Liability Value";
export const CURRENCY = "Currency";
export const CONTENTS_DESCRIPTION = "Contents Description";
export const AUTO_PRINT = "Auto Print";
export const CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES =
  "Cursor goes straight to Total No of Packages";
export const LIMIT_CHARACTERS_MSG =
  "When not using letterhead paper. Max 255 characters";
export const PLEASE_SELECT = "Please Select...";
export const SELECT_A_COUNTRY = "Select a country";
export const SELECT_A_PRODUCTS_SERVICE = "Select a Products/Service";
export const FIELD_IS_MANDATORY = "Field is Mandatory";
export const ERROR_SAVING_PRINTING_SETTINGS = "Error saving printing settings";
export const SELECT_PRINTER = "Select a Printer";
export const SELECT_EACH_TIME = "Select each time";
export const PLEASE_NOTE_THAT_WHET_SHIPPING_PARCELS =
  " Please note that when shipping parcels Saturday and Sunday you will need to arrange a collection. For more information please contact your account manager";
export const DATE_FORMAT_DD_MM_YYYY = "yyyy-MM-dd";
export const ASSET_TRACKING = "Old Asset Sticker Image";
export const ASSET_TRACKING_DPD = "New Asset Sticker Image";
export const SHIPPING = "Shipping";
export const COLLECTIONS = "Collections";
export const DELIVERIES = "Deliveries";
export const SHOP_RETURNS = "Pickup Returns";
export const ADDRESS_BOOK = "Address Book";
export const DEPOT_FINDER = "Depot Finder";
export const MY_DPD = "MyDPD";
export const WELCOME = "Welcome";
export const MY_ACCOUNT_DETAILS = "My Account Details";
export const REPORTS = "Reports";
export const DIAGNOSTICS = "Diagnostics";
export const API = "API";
export const HELP = "Help";
export const LOGOUT = "Logout";
export const HOME = "Home";
export const CONTACT_DETAILS_TEXT = "Contact Details";
export const PRINTING_SETTINGS_TEXT = "Printing Settings";
export const COLLECTION_DEFAULTS_TEXT = "Collection Defaults";
export const SHIPPING_DEFAULTS_TEXT = "Shipping Defaults";
export const INVOICE_SETTINGS_TEXT = "Invoice Settings";
export const MY_DPD_LOCAL = "MyDPD Local";
