import { Redirect, Route, Switch } from "react-router";
import React from "react";

import {
  COLLECTION_DEFAULTS,
  CONTACT_DETAILS,
  INVOICE_SETTING,
  MAIN_PAGE,
  PRINTING_SETTINGS,
  SHIPPING_DEFAULTS,
} from "./constants";
import CollectionDefaults from "../pages/collectionDefaults/CollectionDefaults";
import ContactDetails from "../pages/contactDetails/ContactDetails";
import InvoiceSettings from "../pages/invoiceSettings/InvoiceSettings";
import PrintingSettings from "../pages/printingSettings/PrintingSettings";
import ShippingSettings from "../pages/shippingSettings/ShippingSettings";

export const PreferencesRoutes = () => {
  return (
    <Switch>
      <Redirect from={MAIN_PAGE} exact to={CONTACT_DETAILS} />
      <Route exact path={COLLECTION_DEFAULTS} component={CollectionDefaults} />
      <Route exact path={CONTACT_DETAILS} component={ContactDetails} />
      <Route exact path={INVOICE_SETTING} component={InvoiceSettings} />
      <Route exact path={PRINTING_SETTINGS} component={PrintingSettings} />
      <Route exact path={SHIPPING_DEFAULTS} component={ShippingSettings} />
      <Redirect to={CONTACT_DETAILS} />
    </Switch>
  );
};
