export const PRE_ARRANGED = "autoCollectEnabled";
export const NUMBER_OF_ITEMS_FIELD = "defaultNumItem";
export const WEIGHT_FIELD = "defaultWeight";
export const DEFAULT_PRODUCT = "defaultProduct";
export const CLOSING_TIME_FIELD = "closingTime";
export const DEFAULT_REFERENCE_TEXT_FIELD = "defaultReferenceText";
export const DEFAULT_SERVICE_FIELD = "defaultService";
export const DO_NOT_PROMPT_FOR_COLLECTION =
  "Do not prompt for collection (By clicking this you are stating that your local depot have a daily pre-arranged collection with yourselves)";
export const PREFS_COLLECTIONS_DEFAULTS = "prefsCollectionDefaults";

export const SELECT_PRODUCT = "Select a product";
export const SELECT_SERVICE = "Select a service";

export const FIELDS = [
  NUMBER_OF_ITEMS_FIELD,
  WEIGHT_FIELD,
  CLOSING_TIME_FIELD,
  PRE_ARRANGED,
  DEFAULT_PRODUCT,
  DEFAULT_SERVICE_FIELD,
  DEFAULT_REFERENCE_TEXT_FIELD,
];

export const NUMBER_FIELDS = [NUMBER_OF_ITEMS_FIELD, WEIGHT_FIELD];
