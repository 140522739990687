import { initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getCurrentUser, updateSettings } from "../user/user.actions";
import { CONTACT_DETAILS_FORM } from "../../constants/forms";
import { mapDataToForm } from "./contactDetails.model";
import { PHONE_FIELD } from "./contactDetails.constants";

const ACTION_NAMESPACE = "CONTACT_DETAILS_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  INITIALIZE_FORM: createAsyncActionTypes("INITIALIZE_FORM"),
  UPDATE_CONTACTS: createAsyncActionTypes("UPDATE_CONTACTS"),
});

export const initializeForm = createAsyncAction(
  () => async dispatch => {
    const user = await dispatch(getCurrentUser());
    dispatch(initialize(CONTACT_DETAILS_FORM, mapDataToForm({ user })));
  },
  ACTIONS.INITIALIZE_FORM
);

export const updateContacts = createAsyncAction(
  values => dispatch => {
    return dispatch(
      updateSettings({ ...values, [PHONE_FIELD]: values[PHONE_FIELD].trim() })
    );
  },
  ACTIONS.UPDATE_CONTACTS
);
