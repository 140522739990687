import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink, withRouter } from "react-router-dom";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { compose } from "recompose";

import styles from "./MenuComponent.module.scss";
import {
  COLLECTION_DEFAULTS,
  CONTACT_DETAILS,
  INVOICE_SETTING,
  PRINTING_SETTINGS,
  SHIPPING_DEFAULTS,
} from "../../../router/constants";
import {
  COLLECTION_DEFAULTS_TEXT,
  CONTACT_DETAILS_TEXT,
  INVOICE_SETTINGS_TEXT,
  PRINTING_SETTINGS_TEXT,
  SHIPPING_DEFAULTS_TEXT,
} from "./../../../constants/strings";
import { MAIN_MENU } from "../../../constants/analytics";
import { changeFavicon } from "../../../utils/favicon";

const MenuComponent = ({ location, onMenuClick }) => {
  React.useEffect(() => {
    changeFavicon();
  }, [window.location.pathname]);
  return (
    <div className={styles.container}>
      <NavLink
        onClick={() => onMenuClick(CONTACT_DETAILS)}
        exact={true}
        to={CONTACT_DETAILS}
        className={classNames(
          styles.menuItem,
          location.pathname === "/" && styles.activeLink
        )}
        activeClassName={styles.activeLink}
      >
        {CONTACT_DETAILS_TEXT}
      </NavLink>
      <NavLink
        to={PRINTING_SETTINGS}
        className={styles.menuItem}
        activeClassName={styles.activeLink}
        exact={true}
        onClick={() => onMenuClick(PRINTING_SETTINGS)}
      >
        {PRINTING_SETTINGS_TEXT}
      </NavLink>
      <NavLink
        to={COLLECTION_DEFAULTS}
        className={styles.menuItem}
        activeClassName={styles.activeLink}
        exact={true}
        onClick={() => onMenuClick(COLLECTION_DEFAULTS)}
      >
        {COLLECTION_DEFAULTS_TEXT}
      </NavLink>
      <NavLink
        to={SHIPPING_DEFAULTS}
        className={styles.menuItem}
        activeClassName={styles.activeLink}
        exact={true}
        onClick={() => onMenuClick(SHIPPING_DEFAULTS)}
      >
        {SHIPPING_DEFAULTS_TEXT}
      </NavLink>
      <NavLink
        to={INVOICE_SETTING}
        className={styles.menuItem}
        activeClassName={styles.activeLink}
        exact={true}
        onClick={() => onMenuClick(INVOICE_SETTING)}
      >
        {INVOICE_SETTINGS_TEXT}
      </NavLink>
    </div>
  );
};

export default compose(
  withRouter,
  withTrackProps({
    onMenuClick: name => {
      switch (name) {
        case CONTACT_DETAILS:
          return MAIN_MENU.CLICK_CONTACT_DETAILS;
        case PRINTING_SETTINGS:
          return MAIN_MENU.CLICK_PRINTING_SETTINGS;
        case COLLECTION_DEFAULTS:
          return MAIN_MENU.CLICK_COLLECTION_DEFAULTS;
        case SHIPPING_DEFAULTS:
          return MAIN_MENU.CLICK_SHIPPING_DEFAULTS;
        case INVOICE_SETTING:
          return MAIN_MENU.CLICK_INVOICE_SETTINGS;
      }
    },
  })
)(MenuComponent);

MenuComponent.propTypes = {
  location: PropTypes.object,
  onMenuClick: PropTypes.func,
};
