import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  CONTENTS_DESCRIPTION,
  CURRENCY,
  EXTENDED_LIABILITY,
  EXTENDED_LIABILITY_VALUE,
  INTERNATIONAL_SHIPPING_DEFAULTS,
  NUMBER_OF_ITEMS,
  PLEASE_SELECT,
  PRODUCT_SERVICE,
  REFERENCE_1_PREFIX,
  SELECT_A_PRODUCTS_SERVICE,
  SELECT_EACH_TIME,
  WEIGHT_KG,
} from "../../../constants/strings";
import {
  INT_CONTENT_DESCRIPTION_FIELD,
  INT_CURRENCY_FIELD,
  INT_LIABILITY_FIELD,
  INT_LIABILITY_VALUE_FIELD,
  INT_NUM_ITEMS_FIELD,
  INT_PRODUCT_FIELD,
  INT_REFERENCE_TEXT_FIELD,
  INT_WEIGHT_FIELD,
  LIABILITY_OPTIONS,
} from "../../../features/shippingSettings/shippingSettings.constants";
import SubTitle from "../../../components/Title/SubTitle";
import InputWithTitle from "../../../components/InputWithTitle/InputWithTitle";
import SelectFormField from "../../../components/select/SelectFormField";
import {
  getCurrenciesOptions,
  getNetworkOptions,
} from "../../../features/config/config.selectors";
import { notMathChar, onlyNumbers } from "../../../utils/normalize";
import { SHIPPING_SETTINGS_IDS } from "../../../constants/analytics";

const InternationalShippingSettings = ({ onInputChange }) => {
  const currencies = useSelector(getCurrenciesOptions);
  const network = useSelector(getNetworkOptions);
  return (
    <>
      <SubTitle title={INTERNATIONAL_SHIPPING_DEFAULTS} />
      <Field
        component={SelectFormField}
        label={PRODUCT_SERVICE}
        name={INT_PRODUCT_FIELD}
        placeholder={SELECT_A_PRODUCTS_SERVICE}
        values={network}
        onChange={() => {
          onInputChange(INT_PRODUCT_FIELD);
        }}
      />
      <Field
        name={INT_NUM_ITEMS_FIELD}
        component={InputWithTitle}
        label={NUMBER_OF_ITEMS}
        maxLength={10}
        normalize={onlyNumbers}
      />
      <Field
        name={INT_WEIGHT_FIELD}
        component={InputWithTitle}
        label={WEIGHT_KG}
        maxLength={10}
        normalize={notMathChar}
      />
      <Field
        name={INT_REFERENCE_TEXT_FIELD}
        component={InputWithTitle}
        label={REFERENCE_1_PREFIX}
        maxLength={50}
      />
      <Field
        name={INT_LIABILITY_FIELD}
        component={SelectFormField}
        label={EXTENDED_LIABILITY}
        placeholder={SELECT_EACH_TIME}
        values={LIABILITY_OPTIONS}
        onChange={() => {
          onInputChange(INT_LIABILITY_FIELD);
        }}
      />
      <Field
        name={INT_LIABILITY_VALUE_FIELD}
        component={InputWithTitle}
        label={EXTENDED_LIABILITY_VALUE}
        maxLength={10}
        normalize={onlyNumbers}
      />
      <Field
        name={INT_CURRENCY_FIELD}
        component={SelectFormField}
        label={CURRENCY}
        values={currencies}
        placeholder={PLEASE_SELECT}
        placeholderDisabled
        onChange={() => {
          onInputChange(INT_CURRENCY_FIELD);
        }}
      />
      <Field
        name={INT_CONTENT_DESCRIPTION_FIELD}
        component={InputWithTitle}
        label={CONTENTS_DESCRIPTION}
        maxLength={50}
      />
    </>
  );
};

InternationalShippingSettings.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default compose(
  withTrackProps(() => ({
    onInputChange: type => {
      switch (type) {
        case INT_PRODUCT_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRODUCT_SERVICE;
        case INT_LIABILITY_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_EXTENDED_LIABILITY;
        case INT_CURRENCY_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_CURRENCY;
      }
    },
  }))
)(InternationalShippingSettings);
