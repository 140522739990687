import React from "react";
import { Field } from "redux-form";

import styles from "./DimensionShippingSettings.module.scss";
import {
  DIMENSION_AIR_SERVICES_ONLY,
  HEIGHT_CM,
  LENGTH_CM,
  WEIGHT_KG,
  WIDTH_CM,
} from "../../../constants/strings";
import {
  INT_DIM_HEIGHT_FIELD,
  INT_DIM_LENGTH_FIELD,
  INT_DIM_WEIGHT_FIELD,
  INT_DIM_WIDTH_FIELD,
} from "../../../features/shippingSettings/shippingSettings.constants";
import SubTitle from "../../../components/Title/SubTitle";
import InputWithTitle from "../../../components/InputWithTitle/InputWithTitle";
import { notMathChar, onlyNumbers } from "../../../utils/normalize";

const DimensionShippingSettings = () => {
  return (
    <>
      <SubTitle title={DIMENSION_AIR_SERVICES_ONLY} />
      <div className={styles.row}>
        <Field
          name={INT_DIM_WEIGHT_FIELD}
          component={InputWithTitle}
          label={WEIGHT_KG}
          classNameLabelContainer={styles.label}
          classNameContainer={styles.container}
          classNameField={styles.field}
          classNameError={styles.error}
          maxLength={10}
          normalize={notMathChar}
        />
        <Field
          name={INT_DIM_LENGTH_FIELD}
          component={InputWithTitle}
          label={LENGTH_CM}
          classNameLabelContainer={styles.label}
          classNameContainer={styles.container}
          classNameField={styles.field}
          classNameError={styles.error}
          maxLength={10}
          normalize={onlyNumbers}
        />
        <Field
          name={INT_DIM_WIDTH_FIELD}
          component={InputWithTitle}
          label={WIDTH_CM}
          classNameLabelContainer={styles.label}
          classNameContainer={styles.container}
          classNameField={styles.field}
          classNameError={styles.error}
          maxLength={10}
          normalize={onlyNumbers}
        />
        <Field
          name={INT_DIM_HEIGHT_FIELD}
          component={InputWithTitle}
          label={HEIGHT_CM}
          classNameLabelContainer={styles.label}
          classNameContainer={styles.container}
          classNameField={styles.field}
          classNameError={styles.error}
          maxLength={10}
          normalize={onlyNumbers}
        />
      </div>
    </>
  );
};

export default DimensionShippingSettings;
