import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";
import get from "lodash/get";

import Title from "../../components/Title/Title";
import InnerLayout from "../../components/Layout/InnerLayout";
import styles from "./ContactDetails.module.scss";
import { CONTACT_DETAILS_FORM } from "../../constants/forms";
import {
  CONTACT_DETAILS,
  CONTACT_DETAILS_MSG,
  EMAIL,
  ERROR_SAVING_MSG,
  FORENAME,
  POSITION,
  SURNAME,
  TELEPHONE_NUMBER,
  TITLE,
} from "../../constants/strings";
import {
  EMAIL_FIELD,
  FORENAME_FIELD,
  LASTNAME_FIELD,
  PHONE_FIELD,
  POSITION_FIELD,
  TITLE_FIELD,
  TITLE_VALUES,
} from "../../features/contactDetails/contactDetails.constants";
import InputWithTitle from "../../components/InputWithTitle/InputWithTitle";
import FormBottomSection from "../../components/formBottomSection/FormBottomSection";
import {
  initializeForm,
  updateContacts,
} from "../../features/contactDetails/contactDetails.actions";
import {
  getCurrentUser,
  getUserErrorMessage,
} from "../../features/user/user.selectors";
import { validate } from "../../features/contactDetails/contactDetails.validators";
import SelectFormField from "../../components/select/SelectFormField";
import HintLabel from "../../components/HintLabel/HintLabel";
import { CONTACT_DETAILS_IDS } from "../../constants/analytics";

const ContactDetails = ({
  handleSubmit,
  onInputChange,
  onSave,
  message,
  emailDisabled,
}) => (
  <div className={styles.container}>
    <Title title={CONTACT_DETAILS} />
    <InnerLayout>
      <HintLabel />
      <Field
        required
        name={TITLE_FIELD}
        component={SelectFormField}
        label={TITLE}
        values={TITLE_VALUES}
        onChange={() => {
          onInputChange(TITLE_FIELD);
        }}
      />
      <Field
        name={FORENAME_FIELD}
        component={InputWithTitle}
        label={FORENAME}
        maxLength="35"
        required
        classNameLabel={styles.label}
        classNameField={styles.field}
      />
      <Field
        name={LASTNAME_FIELD}
        component={InputWithTitle}
        label={SURNAME}
        maxLength="35"
        required
        classNameLabel={styles.label}
        classNameField={styles.field}
      />
      <Field
        name={POSITION_FIELD}
        component={InputWithTitle}
        label={POSITION}
        required
        maxLength="50"
        classNameLabel={styles.label}
        classNameField={styles.field}
      />
      <Field
        name={EMAIL_FIELD}
        component={InputWithTitle}
        label={EMAIL}
        required
        maxLength="100"
        disabled={emailDisabled}
        classNameLabel={styles.label}
        classNameField={styles.field}
      />
      <Field
        name={PHONE_FIELD}
        component={InputWithTitle}
        label={TELEPHONE_NUMBER}
        required
        maxLength="15"
        classNameLabel={styles.label}
        classNameField={styles.field}
      />
    </InnerLayout>
    <FormBottomSection onSave={handleSubmit(onSave)} message={message} />
  </div>
);

ContactDetails.propTypes = {
  handleSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
  emailDisabled: PropTypes.bool,
};

const mapStateToProps = state => {
  const message = getUserErrorMessage(state);
  const currentUser = getCurrentUser(state);

  return {
    currentUser,
    message:
      message === ERROR_SAVING_MSG
        ? `${message} ${CONTACT_DETAILS_MSG}`
        : message,
    emailDisabled: currentUser.username === currentUser.email,
  };
};

const mapDispatchToProps = dispatch => ({
  updateContacts: values => dispatch(updateContacts(values)),
  onInitializeForm: () => dispatch(initializeForm()),
});

export default compose(
  reduxForm({
    form: CONTACT_DETAILS_FORM,
    validate,
  }),
  withTrack({
    loadId: CONTACT_DETAILS_IDS.LOAD,
    interfaceId: CONTACT_DETAILS_IDS.INTERFACE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(props => ({
    onSave: values => {
      props.updateContacts(values);
      return createLogEntryPayload(CONTACT_DETAILS_IDS.CLICK_SAVE);
    },
    onInputChange: type => {
      switch (type) {
        case TITLE_FIELD:
          return CONTACT_DETAILS_IDS.CLICK_DROPDOWN_LIST_TITLE;
      }
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitializeForm();
    },
    componentDidUpdate(prevProps) {
      if (
        get(this.props, "currentUser.uid") !== get(prevProps, "currentUser.uid")
      ) {
        this.props.onInitializeForm();
      }
    },
  })
)(ContactDetails);
