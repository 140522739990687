export const PRINT_TO_THERMAL_FIELD = "printToThermal";
export const THERMAL_PRINTER_TYPE_FIELD = "thermalPrinterType";
export const THERMAL_PRINTER_NAME_FIELD = "thermalPrinterName";
export const THERMAL_PRINTER_ASSET_NUMBER_FIELD = "thermalPrinterAssetNumber";
export const MISSING_ASSET_NUMBER_FIELD = "missingAssetNumber";
export const LABELS_PER_PAGE_FIELD = "labelsPerPage";

export const PREFS_CONFIG_SETTINGS = "prefsConfigSettings";

export const MISSING_ASSET_NUMBER = "000000";

export const THERMAL_PRINTER_FIELDS = [
  THERMAL_PRINTER_TYPE_FIELD,
  THERMAL_PRINTER_NAME_FIELD,
  THERMAL_PRINTER_ASSET_NUMBER_FIELD,
  MISSING_ASSET_NUMBER_FIELD,
];

export const THERMAL_PRINTER_TYPES = [
  { label: "Citizen/TSC", value: "2" },
  { label: "Eltron/Zebra", value: "1" },
];
