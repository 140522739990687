import React from "react";
import PropTypes from "prop-types";

import { ThemeContext } from "./themeContext";
import { Theme } from "../constants/theme";
import { isDpdLocalDomain } from "./domain";

const getTheme = () => {
  const isDpdLocal = isDpdLocalDomain();
  return isDpdLocal ? Theme.dpdLocal : Theme.dpd;
};

const ThemeProvider = props => {
  return (
    <ThemeContext.Provider value={{ theme: getTheme() }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default ThemeProvider;
