import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./InputWithTitle.module.scss";

const InputWithTitle = ({
  label,
  type,
  classNameContainer,
  classNameLabelContainer,
  classNameLabel,
  classNameField,
  classNameError,
  meta,
  input,
  required,
  multiline,
  subLabel,
  ...inputProps
}) => {
  return (
    <div
      className={classNames(
        styles.fieldContainer,
        classNameContainer && classNameContainer
      )}
    >
      <div
        className={classNames(
          styles.fieldLabelContainer,
          classNameLabelContainer && classNameLabelContainer
        )}
      >
        <div
          className={classNames(
            styles.fieldLabel,
            classNameLabel && classNameLabel
          )}
        >
          {required && <span className={styles.required}>*</span>}
          {label}
        </div>
        {subLabel && (
          <div
            className={classNames(
              styles.fieldLabel,
              classNameLabel && classNameLabel
            )}
          >
            {subLabel}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.field, classNameField && classNameField)}
      >
        {multiline ? (
          <textarea {...inputProps} {...input} />
        ) : (
          <input
            type={type}
            className={classNames(
              meta.invalid && meta.initial !== undefined && styles.invalid
            )}
            {...inputProps}
            {...input}
            onDrop={e => e.preventDefault()}
          />
        )}
        {meta.invalid && meta.initial !== undefined && (
          <div
            className={classNames(
              styles.error,
              classNameError && classNameError
            )}
          >
            {meta.error}
          </div>
        )}
      </div>
    </div>
  );
};

InputWithTitle.defaultProps = {
  type: "text",
  multiline: false,
  required: false,
};

InputWithTitle.propTypes = {
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  classNameLabelContainer: PropTypes.string,
  classNameError: PropTypes.string,
  subLabel: PropTypes.string,
};

export default InputWithTitle;
