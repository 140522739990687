import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as countriesService from "./country.service";

const ACTION_NAMESPACE = "COUNTRY";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
});

export const fetchCountries = createAsyncAction(
  () => countriesService.getCountriesApi(),
  ACTIONS.FETCH_COUNTRIES
);
