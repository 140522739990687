import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "./overridedDropdownStyles.scss";

import styles from "./CustomDatePicker.module.scss";

const CustomInput = ({ value, onClick }) => (
  <input
    onKeyPress={e => {
      e.preventDefault();
    }}
    value={value}
    onClick={onClick}
  />
);

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const CustomDatePicker = ({
  label,
  placeholderText,
  input,
  onChange,
  classNameContainer,
  classNameLabel,
  ...inputProps
}) => {
  const onSelect = data => {
    const value = data ? data.getTime() : null;
    onChange && onChange(value);
    input.onChange(value);
  };
  return (
    <div
      className={classNames(
        styles.container,
        classNameContainer && classNameContainer
      )}
    >
      <label
        htmlFor={label}
        className={classNames(styles.label, classNameLabel && classNameLabel)}
      >
        {label}
      </label>
      <div>
        <DatePicker
          selected={input.value}
          onSelect={onSelect}
          customInput={<CustomInput />}
          placeholderText={placeholderText}
          {...inputProps}
        />
      </div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  inputProps: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default CustomDatePicker;
