import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./RadioInput.module.scss";

const RadioInput = ({ label, classNameLabel, value, input, ...inputProps }) => {
  return (
    <label className={classNames(styles.label, classNameLabel)}>
      <input type="radio" value {...input} {...inputProps} />
      {label}
    </label>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  value: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default RadioInput;
