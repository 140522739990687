import React from "react";
import { Route } from "react-router";
import { useSelector } from "react-redux";

import { getCurrentUser } from "../features/user/user.selectors";

const AuthRoute = ({ ...props }) => {
  const isAuthenticated = Boolean(useSelector(getCurrentUser));

  if (isAuthenticated) {
    return <Route {...props} />;
  }
  return null;
};
export default AuthRoute;
