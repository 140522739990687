import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { compose, lifecycle, withHandlers } from "recompose";
import PropTypes from "prop-types";

import {
  checkMyDPDAppRunning,
  saveNewPrinterSettings,
} from "../../features/printingSettings/printingSettings.actions";
import { isMyDPDCustomerAppRunning } from "../../features/printingSettings/printingSettings.selectors";
import { MYDPD_CUSTOMER_SETTINGS } from "../../constants/strings";
import SubTitle from "../../components/Title/SubTitle";
import icon from "../../assets/icons/download-arrow.svg";
import { isDpdLocalDomain } from "../../utils/domain";
import styles from "./MyDPDCustomer.module.scss";
import { isElectronEnvironment, isMacOS } from "../../utils/checkUserAgent";
const isFromElectron = isElectronEnvironment();

const tutorialLink = isDpdLocalDomain()
  ? "https://www.dpdlocal.co.uk/lp/mydpd-features/index.html#printer-setup"
  : "https://www.dpd.co.uk/lp/mydpd-features/index.html#printer-setup";

const MyDPDCustomer = ({
  isNewAppRunning,
  isMacPlatform,
  onClickDownloadApp,
  refreshMyDPDAppStatus,
}) => {
  return (
    <div className={styles.container}>
      <SubTitle title={MYDPD_CUSTOMER_SETTINGS} />
      {!isMacPlatform && (
        <div className={styles.statusRow}>
          <div
            className={classNames(
              styles.circle,
              isNewAppRunning ? styles.success : styles.error
            )}
          ></div>
          <div className={styles.status}>
            {isNewAppRunning ? "App running" : "Stopped or not installed"}
            <button
              className={styles.refreshBtn}
              onClick={refreshMyDPDAppStatus}
            >
              Refresh status
            </button>
          </div>
        </div>
      )}
      <div className={styles.downloadRow}>
        <div className={styles.label}>
          In order to use thermal printing/imports in the new MyDPD shipping
          platform, you will need to download and install this file. Once
          downloaded, navigate to your Downloads folder and double click on the
          file that has downloaded. Once the file has been installed, double
          click on the MyDPD Customer icon on the Desktop.
        </div>
        <a
          href={
            isDpdLocalDomain()
              ? process.env.REACT_APP_DOWNLOAD_MYDPD_LOCAL_APP_URL
              : process.env.REACT_APP_DOWNLOAD_MYDPD_APP_URL
          }
          className={styles.downloadButton}
          // setTimeout will prevent fetch cancellation in electron
          onClick={() => setTimeout(onClickDownloadApp, 500)}
        >
          Download Installation file
          <img
            alt="Download"
            className={styles.downloadButtonIcon}
            src={icon}
          />
        </a>
      </div>
      <div id={"tutorial"} className={styles.link}>
        <a target="_blank" rel="noopener noreferrer" href={tutorialLink}>
          View video tutorial
        </a>
      </div>
    </div>
  );
};

MyDPDCustomer.propTypes = {
  currentUser: PropTypes.object,
  onClickDownloadApp: PropTypes.func,
  refreshMyDPDAppStatus: PropTypes.func,
  isNewAppRunning: PropTypes.bool,
  isMacPlatform: PropTypes.bool,
};

const mapStateToProps = state => ({
  isNewAppRunning: isMyDPDCustomerAppRunning(state),
  isMacPlatform: isMacOS(),
});

const mapDispatchToProps = {
  saveNewPrinterSettings,
  checkMyDPDAppRunning,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    refreshMyDPDAppStatus: ({
      checkMyDPDAppRunning,
      saveNewPrinterSettings,
    }) => () =>
      checkMyDPDAppRunning()
        .then(
          () => true,
          () => false
        )
        .then(isRunning => {
          saveNewPrinterSettings({
            isDpdLocalServiceRunning: isRunning,
          });
        }),
    onClickDownloadApp: ({ saveNewPrinterSettings }) => () =>
      saveNewPrinterSettings({
        isDpdLocalServiceDownloaded: true,
      }),
  }),
  lifecycle({
    componentDidMount() {
      !this.props.isMacPlatform && this.props.refreshMyDPDAppStatus();
    },
  })
)(MyDPDCustomer);
