import "firebase/auth";
import { customerFirebase as firebase } from "../../utils/firebase";
import api from "../../utils/api";
import { API_ENDPOINTS } from "../../constants/common";

export const signInWithEmailAndPassword = (...args) =>
  firebase.auth().signInWithEmailAndPassword(...args);

export const signOut = () => firebase.auth().signOut();

export const login = idToken =>
  api.post({
    path: `${API_ENDPOINTS.AUTH}/login`,
    headers: { preferences: true },
    body: { idToken },
  });
