import {
  CUSTOM_DOMAIN,
  dpdLocalDomain,
  LOCAL_DOMAINS,
} from "../constants/common";

const { REACT_APP_DPD_DOMAIN, REACT_APP_DPD_LOCAL_DOMAIN } = process.env;

export const isDpdLocalDomain = () =>
  window.location.hostname.includes(dpdLocalDomain);

export const getDomainLink = () =>
  isDpdLocalDomain() ? REACT_APP_DPD_LOCAL_DOMAIN : REACT_APP_DPD_DOMAIN;

export const isLocalHost = hostname => LOCAL_DOMAINS.includes(hostname);

/*
qa6-www.dpd.co.uk: ok (dpd.co.uk)
customeraddressbook-d1.dpduk.dev: ok (dpduk.dev)
apis.customeraddressbook-d1.dpduk.dev: ok (dpduk.dev)
 */
export const getDomainName = (
  hostname = window.location.hostname,
  defaultDomain
) => {
  const isLocalhost = isLocalHost(hostname);
  if (isLocalhost) {
    return defaultDomain;
  }

  const parts = hostname.split(".").reverse();
  const cnt = parts.length;
  if (cnt >= 3) {
    // see if the second level domain is a common SLD.
    if (parts[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|info|biz)$/i)) {
      return parts[2] + "." + parts[1] + "." + parts[0];
    }
  }
  return parts[1] + "." + parts[0];
};

export const isCustomDomain = domain =>
  Object.values(CUSTOM_DOMAIN).some(customDomain => customDomain === domain);

export const getLocalApisUrl = (location, port = "2917") => {
  const { hostname, protocol } = location;

  const domain = getDomainName(hostname);

  if (isCustomDomain(domain)) {
    return `${protocol}//loopback.${domain}:${port}`;
  }
  return `${protocol}//localhost:${port}`;
};
